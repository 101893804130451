import React, {useEffect, useState} from "react";
import {Grow, Stack} from "@mui/material";
// import ChampionSelect from "../../../../components/selectors/Shared/ChampionSelect";
import {BotlaneMatchupsGrid} from "../../../../components/datagrids/Competitive/BotlaneMatchups";
import {Champion, targets, useReferentialContext} from "../../../../Context";
import WindowContainer from "../../../../components/layout/WindowContainer";
import FilterSet from "../../../../components/selectors/Filterset";

export default function BotlaneMatchups() {
    const context = useReferentialContext();
    // const [state, setState] = useState({
    //     patch: current_patch.toString(),
    //     leagues: current_leagues.map((league: any) => league.league_name),
    //     bottom: "Jinx",
    //     support: "Thresh",
    // });

    const [paramObject, setParamObject] = useState({} as any);
    const [patches, setPatches] = useState(context.selectedPatches);
    const [leagues, setLeagues] = useState(context.selectedLeagues);
    const [adc, setAdc] = useState(null as Champion|null);
    const [support, setSupport] = useState(null as Champion|null);

    // useEffect(() => {
    //     context.farsightApi.computes.botlane_matchups({
    //
    //     })
    // }, []);


    return (
        <WindowContainer>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.competitive} leagues={leagues} setLeagues={setLeagues} patches={patches} setPatches={setPatches}/>

            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <Stack direction="row" sx={{ height: "95%", width: "100%" }}>
                    {/*<BotlaneMatchupsGrid patch={state.patch} leagues={state.leagues} bottom={state.bottom} support={state.support} target={"competitive"} />*/}
                </Stack>
            </Grow>
</WindowContainer>
    );
}
