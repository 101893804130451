import {Champion, targets, useReferentialContext} from "../../Context";
import React, {useEffect, useState} from "react";
import {Fade, Grow, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import FilterSet from "../../components/selectors/Filterset";
import Tabs from "@mui/material/Tabs";
import WindowContainer from "../../components/layout/WindowContainer";
import Tab from "@mui/material/Tab";
import TabPanel, {a11yProps} from "../../components/tabs_switch/tabs_panel";
import Box from "@mui/material/Box";
import PlayerPresentation from "../../components/visu_blocks/PlayerPresentation";
import VariableDistribution from "../../components/Charts/VariableDistribution";
import ChampionsPie from "../../components/Charts/ChampionsPie";
import GamesHistory from "../../components/datagrids/shared/GamesHistory";
import CompetitivePlayerEvolution from "../../components/Charts/CompetitivePlayerEvolution";
import {Player, SoloQAccounts} from "../../farsight-api";
import StatsSummary, {PlayerStatsSummary} from "../../components/visu_blocks/StatsSummary";
import ChampionsStats from "../../components/visu_blocks/ChampionsStats";
import dayjs from "dayjs";
import Proximities from "../../components/Charts/Proximities";
import CounterPickProfiling from "../../components/datagrids/shared/CounterPickProfiling";

export default function CompetitivePlayersDetails() {
    const context = useReferentialContext();

    const [player, setPlayer] = useState(context.selectedPlayer);
    const [year, setYear] = useState(2024);
    const [leagues, setLeagues] = useState(context.selectedLeagues);
    const [champions, setChampions] = useState([] as Champion[]);
    const [split, setSplit] = useState(null as string | null);
    const [phases, setPhases] = useState([] as string[]);
    const [pickStatus, setPickStatus] = useState(null as boolean|null);

    const [paramObject, setParamObject] = useState({
        ordering: "-performance",
    } as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.competitive}
                leagues={leagues}
                setLeagues={setLeagues}
                year={year}
                setYear={setYear}
                split={split}
                setSplit={setSplit}
                phases={phases}
                setPhases={setPhases}
                champions={champions}
                setChampions={setChampions}
                player={player}
                setPlayer={setPlayer}
                picktype={pickStatus}
                setPicktype={setPickStatus}
            />
            {paramObject.player && player ? <PlayerDetails target={targets.competitive} paramObject={paramObject} player={player}/> : <></>}
        </WindowContainer>
    );
}

export function SoloQPlayersDetails() {
    const context = useReferentialContext();

    const [from, setFrom] = useState(dayjs().subtract(90, "day"));
    const [until, setUntil] = useState(dayjs().add(1,"day"));
    const [player, setPlayer] = useState(context.selectedPlayer);
    const [accounts, setAccounts] = useState([] as SoloQAccounts[]);

    const [paramObject, setParamObject] = useState({
        ordering: "-performance",
    } as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.soloq} player={player} setPlayer={setPlayer} date__gte={from} setDateGte={setFrom} date__lt={until} setDateLt={setUntil} soloqAccounts={accounts} setSoloQAccounts={setAccounts}/>
            {paramObject.competitive_player && player ? <PlayerDetails target={targets.soloq} paramObject={paramObject} player={player}/> : <></>}
        </WindowContainer>
    );
}

export function ScrimPlayerDetails(){
    const [from, setFrom] = useState(dayjs().subtract(90, "day"));
    const [until, setUntil] = useState(dayjs().add(1,"day"));
    const [scrimAccount, setScrimAccount] = useState(null as null|string);

    const [paramObject, setParamObject] = useState({
        ordering: "-performance",
    } as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.scrims} scrimAccount={scrimAccount} setScrimAccount={setScrimAccount} date__gte={from} setDateGte={setFrom} date__lt={until} setDateLt={setUntil}/>
            {paramObject.player && scrimAccount ? <PlayerDetails target={targets.scrims} paramObject={paramObject} player={scrimAccount}/> : <></>}
        </WindowContainer>
    );
}

function PlayerDetails(props: { paramObject: any; target: targets, player: Player|string }) {
    const paramObject = props.paramObject;
    const [tab, setTab] = useState(0);


    return (
        <Stack direction={"column"} spacing={2} sx={{ height: "100%" }}>
            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
                    <Tab label="Overview" {...a11yProps(0)} />
                    <Tab label="Champions" {...a11yProps(1)} />
                    <Tab label="CP Profile" {...a11yProps(2)} />
                    <Tab label="History" {...a11yProps(3)} />
                    <Tab label="Evolution" {...a11yProps(4)} />
                    <Tab label="Lane Proximity" {...a11yProps(5)} />
                </Tabs>
            </Grow>
            <TabPanel value={tab} index={0}>
                {/*@ts-ignore*/}
                {props.target === targets.competitive ? <CompetitivePlayerOverview target={props.target} paramObject={paramObject} player={props.player}/> : <></>}
                {props.target === targets.scrims ? <ScrimPlayerOverview target={props.target} paramObject={paramObject} player={props.player as any}/> : <></>}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <ChampionsStats paramObject={props.paramObject} target={props.target}/>
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <CounterPickProfiling paramObject={props.paramObject} target={props.target}/>
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <GamesHistory paramObject={paramObject} target={props.target}/>
            </TabPanel>
            <TabPanel value={tab} index={4}>
                {/*@ts-ignore*/}
                {props.target === targets.competitive ? <CompetitivePlayerEvolution player={props.player} year={paramObject.additional_filters.date__year} /> : <></>}
            </TabPanel>
            <TabPanel value={tab} index={5}>
                <Proximities paramObject={paramObject} target={props.target} timer={'pre15'}/>
            </TabPanel>
        </Stack>
    );
}

export function CompetitivePlayerOverview(props: {player: Player, paramObject: any, target: targets}) {
    const [statsOverview, setStatsOverview] = useState(undefined as any);
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);

    useEffect(() => {

        const request_parameters = {
            ...props.paramObject,
            groupby: 'player',
            metrics: ["games", "winrate", "kda", "cspm", "gd15", "xpd15", "csd15", "dpm", "gpm",
                'relativeKills', 'relativeDeaths', 'relativeDamages', 'relativeDamageTaken', 'relativeGolds', 'relativeDmgHealed', 'relativeDmgMitigated', 'relativeWardsPlaced', 'relativeWardsKilled', 'relativeLevel', 'relativeCCtime'].toString(),
            aggregates: ['relative_performance__avg','performance__avg','relative_performance__std','performance__std',
                'performance__avg__in_wins', 'performance__std__in_wins',
                'performance__avg__in_losses', 'performance__std__in_losses',
                'relative_performance__avg__in_wins', 'relative_performance__std__in_wins',
                'relative_performance__avg__in_losses', 'relative_performance__std__in_losses',
            ].toString(),
            format: "json",
            ordering: 'performance__avg'
        };
        api.aggregate(request_parameters).then((result: any) => {
                setStatsOverview(result ? result[0] : undefined);
            }
        );
    }, [props.paramObject.watermark]);


    return (
        props.paramObject.player ? <Stack
            component={"fieldset"}
            direction={"row"}
            sx={{
                borderRadius: 3,
                height: window.innerHeight - 300 + "px",
                justifyContent: "space-evenly",
                width: "100%",
                alignItems: "center",
                m: 1,
            }}
            spacing={3}
        >
            {props.player ? <PlayerPresentation player={props.player}/> : <></>}
            {statsOverview ? <StatsSummary data={statsOverview}/> : <Typography>No games for selected preset</Typography>}
            <Fade in={!!props.player} style={{ transformOrigin: "0 100 0" }} {...{ timeout: 4000 }}>
                <Stack
                    direction={"column"}
                    sx={{
                        height: "100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-around",
                        width: "25%",
                    }}
                >
                    <Box sx={{ height: "45%", width: "100%" }}>
                        {statsOverview ? <VariableDistribution paramObject={props.paramObject} target={props.target}
                                               bucket_size={props.target !== "soloq" ? 30 : 20} min={-250} max={250}
                                               metric={"performance"} label={"Performance Distribution"}
                                               gaussianParameters={[
                                                   {
                                                       mean: statsOverview['performance__avg'],
                                                       std: statsOverview['performance__std'],
                                                       label: 'Distribution',
                                                   },
                                                   {
                                                       mean: statsOverview['performance__avg__in_wins'],
                                                       std: statsOverview['performance__std__in_wins'],
                                                       label: 'In wins',
                                                       multiplier: statsOverview['winrate'],
                                                       color: "rgb(100, 100, 255)"
                                                   },
                                                   {
                                                       mean: statsOverview['performance__avg__in_losses'],
                                                       std: statsOverview['performance__std__in_losses'],
                                                       label: 'In losses',
                                                       multiplier: 1 - statsOverview['winrate'],
                                                       color: "rgb(255, 100, 100)"
                                                   },
                                               ]}/> : <></>}
                    </Box>
                    <Box sx={{ height: "45%", width: "100%" }}>
                        {statsOverview ? <VariableDistribution
                            paramObject={props.paramObject}
                            target={props.target}
                            bucket_size={props.target !== "soloq" ? 30 : 10}
                            min={-150} max={150}
                            metric={"relative_performance"} label={"Relative Performance Distribution"}
                            gaussianParameters={[
                                {mean: statsOverview['relative_performance__avg'], std: statsOverview['relative_performance__std'], multiplier: 1, label: 'Relative Performance'},
                                {mean: statsOverview['relative_performance__avg__in_wins'], std: statsOverview['relative_performance__std__in_wins'], label: 'In wins', multiplier: statsOverview['winrate'], color:"rgb(100, 100, 255)"},
                                {mean: statsOverview['relative_performance__avg__in_losses'], std: statsOverview['relative_performance__std__in_losses'], label: 'In losses', multiplier: 1 - statsOverview['winrate'], color:"rgb(255, 100, 100)"},
                            ]}/> : <></>}
                    </Box>
                </Stack>
            </Fade>
            <Grow in={true} style={{ transformOrigin: "0 100 0" }} {...{ timeout: 4000 }}>
                <Stack
                    direction={"column"}
                    sx={{
                        height: "100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-around",
                        width: "25%",
                    }}
                >
                    <Box sx={{ height: "65%", width: "100%" }}>
                        <ChampionsPie  paramObject={props.paramObject} target={props.target}/>
                    </Box>
                </Stack>
            </Grow>
        </Stack> : <></>
    );
}


function ScrimPlayerOverview(props: {player: string, paramObject: any, target: targets}) {
    const [statsOverview, setStatsOverview] = useState(undefined as any);
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);

    useEffect(() => {

        const request_parameters = {
            ...props.paramObject,
            groupby: 'player',
            metrics: ["games", "winrate", "kda", "cspm", "gd15", "xpd15", "csd15", "dpm", "gpm", "cp_frequency","gd8",'csd8'].toString(),
            aggregates: ['relative_performance__avg','performance__avg','relative_performance__std','performance__std',
                'performance__avg__in_wins', 'performance__std__in_wins', 'solo_kills__avg', 'solo_deaths__avg', 'vision_wards_bought__sum__per_10_minute',
                'performance__avg__in_losses', 'performance__std__in_losses', 'forward_percentage_pre_15__avg',
                'relative_performance__avg__in_wins', 'relative_performance__std__in_wins',
                'relative_performance__avg__in_losses', 'relative_performance__std__in_losses',
                'deaths__sum__per_game', 'deaths__avg__in_wins', 'deaths__avg__in_losses',
                'relativeKills_score__avg', 'relativeDeaths_score__avg', 'relativeDamages_score__avg',
                'relativeDamageTaken_score__avg', 'relativeGolds_score__avg',
                'relativeDmgHealed_score__avg',
                'relativeDmgMitigated_score__avg', 'relativeWardsPlaced_score__avg', 'relativeWardsKilled_score__avg', 'relativeLevel_score__avg', 'relativeCCtime_score__avg'
            ].toString(),
            format: "json",
            ordering: 'performance__avg'
        };
        api.aggregate(request_parameters).then(result => {
                setStatsOverview(result ? result[0] : undefined);
            }
        );
    }, [props.paramObject.watermark]);


    return (
        props.paramObject.player && statsOverview ? <Stack
            component={"fieldset"}
            direction={"row"}
            sx={{
                borderRadius: 3,
                height: window.innerHeight - 300 + "px",
                justifyContent: "space-evenly",
                width: "100%",
                alignItems: "center",
                m: 1,
            }}
            spacing={3}
        >
            {statsOverview ? <PlayerStatsSummary data={statsOverview}/> : <Typography>No games for selected preset</Typography>}
            <Fade in={!!props.player} style={{ transformOrigin: "0 100 0" }} {...{ timeout: 4000 }}>
                <Stack
                    direction={"column"}
                    sx={{
                        height: "100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-around",
                        width: "25%",
                    }}
                >
                    {/*<Box sx={{ height: "45%", width: "100%" }}>*/}
                    {/*    <VariableDistribution paramObject={props.paramObject} target={props.target} bucket_size={props.target !== "soloq" ? 30 : 20} min={-250} max={250} metric={"performance"} label={"Performance Distribution"}*/}
                    {/*                          gaussianParameters={statsOverview ? [*/}
                    {/*                              {mean: statsOverview['performance__avg'], std: statsOverview['performance__std'], label: 'Distribution'},*/}
                    {/*                              {mean: statsOverview['performance__avg__in_wins'], std: statsOverview['performance__std__in_wins'], label: 'In wins', multiplier: statsOverview['winrate'], color:"rgb(100, 100, 255)"},*/}
                    {/*                              {mean: statsOverview['performance__avg__in_losses'], std: statsOverview['performance__std__in_losses'], label: 'In losses', multiplier: 1 - statsOverview['winrate'], color:"rgb(255, 100, 100)"},*/}
                    {/*                          ] : undefined}/>*/}
                    {/*</Box>*/}
                    {/*<Box sx={{ height: "45%", width: "100%" }}>*/}
                    {/*    <VariableDistribution*/}
                    {/*        paramObject={props.paramObject}*/}
                    {/*        target={props.target}*/}
                    {/*        bucket_size={props.target !== "soloq" ? 30 : 10}*/}
                    {/*        min={-150} max={150}*/}
                    {/*        metric={"relative_performance"} label={"Relative Performance Distribution"}*/}
                    {/*        gaussianParameters={statsOverview ? [*/}
                    {/*            {mean: statsOverview['relative_performance__avg'], std: statsOverview['relative_performance__std']},*/}
                    {/*            {mean: statsOverview['relative_performance__avg__in_wins'], std: statsOverview['relative_performance__std__in_wins'], label: 'In wins', multiplier: statsOverview['winrate'], color:"rgb(100, 100, 255)"},*/}
                    {/*            {mean: statsOverview['relative_performance__avg__in_losses'], std: statsOverview['relative_performance__std__in_losses'], label: 'In losses', multiplier: 1 - statsOverview['winrate'], color:"rgb(255, 100, 100)"},*/}
                    {/*        ] : undefined}/>*/}
                    {/*</Box>*/}
                </Stack>
            </Fade>
            <Grow in={true} style={{ transformOrigin: "0 100 0" }} {...{ timeout: 4000 }}>
                <Stack
                    direction={"column"}
                    sx={{
                        height: "100%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-around",
                        width: "25%",
                    }}
                >
                    <Box sx={{ height: "45%", width: "100%" }}>
                        <ChampionsPie  paramObject={props.paramObject} target={props.target}/>
                    </Box>
                </Stack>
            </Grow>
        </Stack> : <></>
    );
}


