/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";

export interface ChampionsRatingsRequest {
    role: string;
    target: string;
    additional_filters?: object;
    detailed?: string;
    is_cp?: boolean;
}

export interface ComputesMetaBreakersRetrieveRequest {
    champions_to_beat: string;
    role: string;
    target: string;
    additional_filters?: object;
}

/**
 *
 */
export class ComputesApi extends runtime.BaseAPI {
    /**
     */
    async championsRatingsRaw(
        requestParameters: ChampionsRatingsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["role"] == null) {
            throw new runtime.RequiredError(
                "role",
                'Required parameter "role" was null or undefined when calling championsRatings().',
            );
        }

        if (requestParameters["target"] == null) {
            throw new runtime.RequiredError(
                "target",
                'Required parameter "target" was null or undefined when calling championsRatings().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["detailed"] != null) {
            queryParameters["detailed"] = requestParameters["detailed"];
        }

        if (requestParameters["is_cp"] != null) {
            queryParameters["is_cp"] = requestParameters["is_cp"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["target"] != null) {
            queryParameters["target"] = requestParameters["target"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Computes/ChampionsRatings`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async champion_ratings(
        requestParameters: ChampionsRatingsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.championsRatingsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async computesMetaBreakersRetrieveRaw(
        requestParameters: ComputesMetaBreakersRetrieveRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["champions_to_beat"] == null) {
            throw new runtime.RequiredError(
                "champions_to_beat",
                'Required parameter "champions_to_beat" was null or undefined when calling computesMetaBreakersRetrieve().',
            );
        }

        if (requestParameters["role"] == null) {
            throw new runtime.RequiredError(
                "role",
                'Required parameter "role" was null or undefined when calling computesMetaBreakersRetrieve().',
            );
        }

        if (requestParameters["target"] == null) {
            throw new runtime.RequiredError(
                "target",
                'Required parameter "target" was null or undefined when calling computesMetaBreakersRetrieve().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["champions_to_beat"] != null) {
            queryParameters["champions_to_beat"] =
                requestParameters["champions_to_beat"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["target"] != null) {
            queryParameters["target"] = requestParameters["target"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Computes/MetaBreakers`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async meta_breakers(
        requestParameters: ComputesMetaBreakersRetrieveRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.computesMetaBreakersRetrieveRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async computesPlayerRankedStatsRetrieveRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Computes/PlayerRankedStats`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async playerRankedStats(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.computesPlayerRankedStatsRetrieveRaw(initOverrides);
    }

    /**
     */
    async computesVariableDistributionRetrieveRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Computes/VariableDistribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.computesVariableDistributionRetrieveRaw(initOverrides);
    }
}
