import {targets, useReferentialContext} from "../../Context";
import React, {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {HighlightScope, pieArcLabelClasses, PieChart} from "@mui/x-charts";
import html2canvas from "html2canvas";
import {useThemeContext} from "../../themeContext";
import Button from "@mui/material/Button";
import {PieCenterLabel} from "./ChampionsPie";
import {Stack, Typography} from "@mui/material";


export default function Proximities(props: {paramObject: any, target: targets, timer:string}) {
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [prox, setProx] = useState(undefined as any);
    const [highlighted, setHighlighted] = useState('item');
    const [faded, setFaded] = useState('global');

    const labels = [
        [`toplane_proximity_${props.timer}__avg`, 'Top'],
        [`jungle_proximity_${props.timer}__avg`, 'Jungle'],
        [`midlane_proximity_${props.timer}__avg`, 'Mid'],
        [`botlane_proximity_${props.timer}__avg`, 'Adc'],
        [`support_proximity_${props.timer}__avg`, 'Support'],
    ]
    const theme = useThemeContext();
    const chartRef = useRef(null);
    const handleCapture = () => {
      if (chartRef.current) {
          // Ensure that the DataGrid is fully rendered before capturing
          // await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust delay if necessary


          // @ts-ignore
          html2canvas(chartRef.current, {
              logging: true,
              useCORS: true,
              allowTaint: true,
              backgroundColor: theme.mode === 'dark' ? '#000000' : '#F7F4EF',
          }).then((canvas) => {
              canvas.toBlob(async (blob) => {
              try {
                await navigator.clipboard.write([
                    // @ts-ignore
                  new ClipboardItem({ 'image/png': blob })
                ]);
                // alert('Image copied to clipboard!');
              } catch (err) {
                console.error('Failed to copy image to clipboard: ', err);
                alert('Failed to copy image to clipboard')
              }
            }, 'image/png');
          })
      }
    }

    useEffect(() => {
        api.aggregate(
            {
                ...props.paramObject,
                groupby: ['role'],
                aggregates:['toplane_proximity_pre15__avg,jungle_proximity_pre15__avg,midlane_proximity_pre15__avg,botlane_proximity_pre15__avg,support_proximity_pre15__avg,toplane_proximity_post15__avg,jungle_proximity_post15__avg,midlane_proximity_post15__avg,botlane_proximity_post15__avg,support_proximity_post15__avg'],
                ordering:['toplane_proximity_pre15__avg']
            }
        ).then(result => setProx(result[0]))
        // FarsightApi.get_request("PlayerPerformanceEvolution", props).then((data) => setRows(data));
        // fetch(api_url + "PlayerPerformanceEvolution/?player=" + player + "&format=json").then(response => response.json()).then(data => setRows(data));
    }, [props.paramObject.watermark]);



    return prox ? (
        <Box
             sx={{
                 borderRadius: 3,
                 height: "340px",
                 width: "100%",
                 alignItems: "center",
                 justifyContent: "center",
                 display: "flex",
             }}
        >
            <Box sx={{width:'100%', height:'80%'}} ref={chartRef}>
                <PieChart
                    series={[
                        {
                            type: 'pie',
                            innerRadius: 70,
                            outerRadius: 120,
                            // data: data.map((e: any) => {return {label: `${e.champion}`, getValue: e.win__sum}}),
                            data: labels.map((v, index) => {return {label: v[1], value: prox[v[0]] >= 0.9 ? 0 : prox[v[0]]}}),
                            highlighted: { additionalRadius: 10 },
                            highlightScope: {
                                highlighted,
                                faded,
                            } as HighlightScope,
                            paddingAngle: 2,
                            cornerRadius: 5,
                            arcLabel: (item) => `${item.label} \n ${(item.value*100).toFixed(2)}%`,
                            arcLabelMinAngle: 20,
                        },
                    ]}
                    margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    slotProps={{
                        legend: {
                            hidden: true,
                            itemMarkWidth: 20,
                            itemMarkHeight: 2,
                            markGap: 5,
                            itemGap: 10,
                            labelStyle: {
                                fontSize: 14,
                                fill: 'blue',
                            },
                        },

                    }}
                    sx={{height:'100%',
                        [`& .${pieArcLabelClasses.root}`]: {
                            fill: theme.mode === 'dark' ? 'white' : 'black',
                            fontWeight: 'bold',
                        },
                    }}
                ><PieCenterLabel>{`${props.target.charAt(0).toUpperCase() + props.target.slice(1).toLowerCase()}\n${props.timer === 'pre15' ? 'Pre 15' : 'Post 15'}`}</PieCenterLabel></PieChart>
                <Button onClick={() => handleCapture()} variant={'outlined'}>PNG</Button>
            </Box>
        </Box>
    ) : <></>;
}