/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    Agencies,
    AgenciesAggregate,
    AgenciesVariableDistribution200ResponseInner,
} from "../models/index";

export interface AgenciesAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    having?: object;
    index_on?: string;
    metrics?: Array<string>;
    name?: string;
    regions?: string;
}

export interface AgenciesFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    name?: string;
    regions?: string;
}

export interface AgenciesGetRequest {
    id: string;
}

export interface AgenciesListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    name?: string;
    ordering?: Array<string>;
    regions?: string;
}

export interface AgenciesVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    name?: string;
    regions?: string;
}

/**
 *
 */
export class AgenciesApi extends runtime.BaseAPI {
    /**
     */
    async agenciesAggregateRaw(
        requestParameters: AgenciesAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<AgenciesAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling agenciesAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling agenciesAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["regions"] != null) {
            queryParameters["regions"] = requestParameters["regions"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Agencies/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: AgenciesAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesAggregate>> {
        const response = await this.agenciesAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async agenciesFieldValuesRaw(
        requestParameters: AgenciesFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling agenciesFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling agenciesFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["regions"] != null) {
            queryParameters["regions"] = requestParameters["regions"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Agencies/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: AgenciesFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.agenciesFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async agenciesGetRaw(
        requestParameters: AgenciesGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Agencies>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling agenciesGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Agencies/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: AgenciesGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Agencies> {
        const response = await this.agenciesGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async agenciesListRaw(
        requestParameters: AgenciesListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<Agencies>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["regions"] != null) {
            queryParameters["regions"] = requestParameters["regions"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Agencies/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: AgenciesListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<Agencies>> {
        const response = await this.agenciesListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async agenciesVariableDistributionRaw(
        requestParameters: AgenciesVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling agenciesVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling agenciesVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling agenciesVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling agenciesVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["name"] != null) {
            queryParameters["name"] = requestParameters["name"];
        }

        if (requestParameters["regions"] != null) {
            queryParameters["regions"] = requestParameters["regions"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Agencies/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: AgenciesVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.agenciesVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
