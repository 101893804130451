/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    OffSeasonShortlistItem,
    OffSeasonShortlistItemAggregate,
    PatchedOffSeasonShortlistItem,
} from "../models/index";

export interface OffSeasonShortlistItemAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    having?: object;
    index_on?: string;
    metrics?: Array<string>;
    player?: string;
    shortlist?: number;
}

export interface OffSeasonShortlistItemCreateRequest {
    id: string;
    off_season_shortlist_item: Omit<OffSeasonShortlistItem, "id">;
}

export interface OffSeasonShortlistItemDeleteRequest {
    id: string;
}

export interface OffSeasonShortlistItemFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    player?: string;
    shortlist?: number;
}

export interface OffSeasonShortlistItemGetRequest {
    id: string;
}

export interface OffSeasonShortlistItemListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    ordering?: Array<string>;
    player?: string;
    shortlist?: number;
}

export interface OffSeasonShortlistItemPatchRequest {
    id: string;
    patched_off_season_shortlist_item?: Omit<
        PatchedOffSeasonShortlistItem,
        "id"
    >;
}

export interface OffSeasonShortlistItemVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    player?: string;
    shortlist?: number;
}

/**
 *
 */
export class OffSeasonShortlistItemApi extends runtime.BaseAPI {
    /**
     */
    async offSeasonShortlistItemAggregateRaw(
        requestParameters: OffSeasonShortlistItemAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<OffSeasonShortlistItemAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling offSeasonShortlistItemAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling offSeasonShortlistItemAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["shortlist"] != null) {
            queryParameters["shortlist"] = requestParameters["shortlist"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/OffSeasonShortlistItem/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: OffSeasonShortlistItemAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<OffSeasonShortlistItemAggregate>> {
        const response = await this.offSeasonShortlistItemAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async offSeasonShortlistItemCreateRaw(
        requestParameters: OffSeasonShortlistItemCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OffSeasonShortlistItem>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling offSeasonShortlistItemCreate().',
            );
        }

        if (requestParameters["off_season_shortlist_item"] == null) {
            throw new runtime.RequiredError(
                "off_season_shortlist_item",
                'Required parameter "off_season_shortlist_item" was null or undefined when calling offSeasonShortlistItemCreate().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/OffSeasonShortlistItem/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["off_season_shortlist_item"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async create(
        requestParameters: OffSeasonShortlistItemCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OffSeasonShortlistItem> {
        const response = await this.offSeasonShortlistItemCreateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async offSeasonShortlistItemDeleteRaw(
        requestParameters: OffSeasonShortlistItemDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<void>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling offSeasonShortlistItemDelete().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/OffSeasonShortlistItem/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "DELETE",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async delete(
        requestParameters: OffSeasonShortlistItemDeleteRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<void> {
        await this.offSeasonShortlistItemDeleteRaw(
            requestParameters,
            initOverrides,
        );
    }

    /**
     */
    async offSeasonShortlistItemFieldValuesRaw(
        requestParameters: OffSeasonShortlistItemFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling offSeasonShortlistItemFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling offSeasonShortlistItemFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["shortlist"] != null) {
            queryParameters["shortlist"] = requestParameters["shortlist"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/OffSeasonShortlistItem/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: OffSeasonShortlistItemFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.offSeasonShortlistItemFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async offSeasonShortlistItemGetRaw(
        requestParameters: OffSeasonShortlistItemGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OffSeasonShortlistItem>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling offSeasonShortlistItemGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/OffSeasonShortlistItem/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: OffSeasonShortlistItemGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OffSeasonShortlistItem> {
        const response = await this.offSeasonShortlistItemGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async offSeasonShortlistItemListRaw(
        requestParameters: OffSeasonShortlistItemListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<OffSeasonShortlistItem>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["shortlist"] != null) {
            queryParameters["shortlist"] = requestParameters["shortlist"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/OffSeasonShortlistItem/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: OffSeasonShortlistItemListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<OffSeasonShortlistItem>> {
        const response = await this.offSeasonShortlistItemListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async offSeasonShortlistItemPatchRaw(
        requestParameters: OffSeasonShortlistItemPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<OffSeasonShortlistItem>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling offSeasonShortlistItemPatch().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/OffSeasonShortlistItem/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "PATCH",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["patched_off_season_shortlist_item"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async patch(
        requestParameters: OffSeasonShortlistItemPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<OffSeasonShortlistItem> {
        const response = await this.offSeasonShortlistItemPatchRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async offSeasonShortlistItemVariableDistributionRaw(
        requestParameters: OffSeasonShortlistItemVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling offSeasonShortlistItemVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling offSeasonShortlistItemVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling offSeasonShortlistItemVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling offSeasonShortlistItemVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["shortlist"] != null) {
            queryParameters["shortlist"] = requestParameters["shortlist"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/OffSeasonShortlistItem/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: OffSeasonShortlistItemVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response =
            await this.offSeasonShortlistItemVariableDistributionRaw(
                requestParameters,
                initOverrides,
            );
        return await response.value();
    }
}
