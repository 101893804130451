/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    WeeklySoloQChampionRating,
    WeeklySoloQChampionRatingAggregate,
} from "../models/index";

export interface WeeklySoloQChampionRatingAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    champion?: string;
    champion_patch?: string;
    end?: string;
    having?: object;
    index_on?: string;
    league_group?: string;
    metrics?: Array<string>;
    patch?: string;
    role?: string;
    servers?: string;
    start?: string;
}

export interface WeeklySoloQChampionRatingFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    champion?: string;
    champion_patch?: string;
    end?: string;
    league_group?: string;
    patch?: string;
    role?: string;
    servers?: string;
    start?: string;
}

export interface WeeklySoloQChampionRatingGetRequest {
    id: string;
}

export interface WeeklySoloQChampionRatingListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    champion?: string;
    champion_patch?: string;
    end?: string;
    league_group?: string;
    ordering?: Array<string>;
    patch?: string;
    role?: string;
    servers?: string;
    start?: string;
}

export interface WeeklySoloQChampionRatingVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    champion?: string;
    champion_patch?: string;
    end?: string;
    league_group?: string;
    patch?: string;
    role?: string;
    servers?: string;
    start?: string;
}

/**
 *
 */
export class WeeklySoloQChampionRatingApi extends runtime.BaseAPI {
    /**
     */
    async weeklySoloQChampionRatingAggregateRaw(
        requestParameters: WeeklySoloQChampionRatingAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<WeeklySoloQChampionRatingAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling weeklySoloQChampionRatingAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling weeklySoloQChampionRatingAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["end"] != null) {
            queryParameters["end"] = requestParameters["end"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["league_group"] != null) {
            queryParameters["league_group"] = requestParameters["league_group"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["servers"] != null) {
            queryParameters["servers"] = requestParameters["servers"];
        }

        if (requestParameters["start"] != null) {
            queryParameters["start"] = requestParameters["start"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/WeeklySoloQChampionRating/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: WeeklySoloQChampionRatingAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<WeeklySoloQChampionRatingAggregate>> {
        const response = await this.weeklySoloQChampionRatingAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async weeklySoloQChampionRatingFieldValuesRaw(
        requestParameters: WeeklySoloQChampionRatingFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling weeklySoloQChampionRatingFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling weeklySoloQChampionRatingFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["end"] != null) {
            queryParameters["end"] = requestParameters["end"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["league_group"] != null) {
            queryParameters["league_group"] = requestParameters["league_group"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["servers"] != null) {
            queryParameters["servers"] = requestParameters["servers"];
        }

        if (requestParameters["start"] != null) {
            queryParameters["start"] = requestParameters["start"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/WeeklySoloQChampionRating/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: WeeklySoloQChampionRatingFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.weeklySoloQChampionRatingFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async weeklySoloQChampionRatingGetRaw(
        requestParameters: WeeklySoloQChampionRatingGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<WeeklySoloQChampionRating>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling weeklySoloQChampionRatingGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/WeeklySoloQChampionRating/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: WeeklySoloQChampionRatingGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<WeeklySoloQChampionRating> {
        const response = await this.weeklySoloQChampionRatingGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async weeklySoloQChampionRatingListRaw(
        requestParameters: WeeklySoloQChampionRatingListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<WeeklySoloQChampionRating>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["end"] != null) {
            queryParameters["end"] = requestParameters["end"];
        }

        if (requestParameters["league_group"] != null) {
            queryParameters["league_group"] = requestParameters["league_group"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["servers"] != null) {
            queryParameters["servers"] = requestParameters["servers"];
        }

        if (requestParameters["start"] != null) {
            queryParameters["start"] = requestParameters["start"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/WeeklySoloQChampionRating/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: WeeklySoloQChampionRatingListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<WeeklySoloQChampionRating>> {
        const response = await this.weeklySoloQChampionRatingListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async weeklySoloQChampionRatingVariableDistributionRaw(
        requestParameters: WeeklySoloQChampionRatingVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling weeklySoloQChampionRatingVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling weeklySoloQChampionRatingVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling weeklySoloQChampionRatingVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling weeklySoloQChampionRatingVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["end"] != null) {
            queryParameters["end"] = requestParameters["end"];
        }

        if (requestParameters["league_group"] != null) {
            queryParameters["league_group"] = requestParameters["league_group"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["servers"] != null) {
            queryParameters["servers"] = requestParameters["servers"];
        }

        if (requestParameters["start"] != null) {
            queryParameters["start"] = requestParameters["start"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/WeeklySoloQChampionRating/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: WeeklySoloQChampionRatingVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response =
            await this.weeklySoloQChampionRatingVariableDistributionRaw(
                requestParameters,
                initOverrides,
            );
        return await response.value();
    }
}
