/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    CompetitiveChampionRatings,
    CompetitiveChampionRatingsAggregate,
} from "../models/index";

export interface CompetitiveChampionRatingsAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    champion?: string;
    champion_patch?: string;
    games?: number;
    having?: object;
    id?: string;
    index_on?: string;
    league_group?: string;
    metrics?: Array<string>;
    patch?: string;
    role?: string;
}

export interface CompetitiveChampionRatingsFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    champion?: string;
    champion_patch?: string;
    games?: number;
    id?: string;
    league_group?: string;
    patch?: string;
    role?: string;
}

export interface CompetitiveChampionRatingsGetRequest {
    id: string;
}

export interface CompetitiveChampionRatingsListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    champion?: string;
    champion_patch?: string;
    games?: number;
    id?: string;
    league_group?: string;
    ordering?: Array<string>;
    patch?: string;
    role?: string;
}

export interface CompetitiveChampionRatingsVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    champion?: string;
    champion_patch?: string;
    games?: number;
    id?: string;
    league_group?: string;
    patch?: string;
    role?: string;
}

/**
 *
 */
export class CompetitiveChampionRatingsApi extends runtime.BaseAPI {
    /**
     */
    async competitiveChampionRatingsAggregateRaw(
        requestParameters: CompetitiveChampionRatingsAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<CompetitiveChampionRatingsAggregate>>
    > {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling competitiveChampionRatingsAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling competitiveChampionRatingsAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["games"] != null) {
            queryParameters["games"] = requestParameters["games"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["league_group"] != null) {
            queryParameters["league_group"] = requestParameters["league_group"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveChampionRatings/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: CompetitiveChampionRatingsAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveChampionRatingsAggregate>> {
        const response = await this.competitiveChampionRatingsAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveChampionRatingsFieldValuesRaw(
        requestParameters: CompetitiveChampionRatingsFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling competitiveChampionRatingsFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling competitiveChampionRatingsFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["games"] != null) {
            queryParameters["games"] = requestParameters["games"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["league_group"] != null) {
            queryParameters["league_group"] = requestParameters["league_group"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveChampionRatings/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: CompetitiveChampionRatingsFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.competitiveChampionRatingsFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveChampionRatingsGetRaw(
        requestParameters: CompetitiveChampionRatingsGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CompetitiveChampionRatings>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling competitiveChampionRatingsGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveChampionRatings/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: CompetitiveChampionRatingsGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CompetitiveChampionRatings> {
        const response = await this.competitiveChampionRatingsGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveChampionRatingsListRaw(
        requestParameters: CompetitiveChampionRatingsListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CompetitiveChampionRatings>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["games"] != null) {
            queryParameters["games"] = requestParameters["games"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["league_group"] != null) {
            queryParameters["league_group"] = requestParameters["league_group"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveChampionRatings/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: CompetitiveChampionRatingsListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveChampionRatings>> {
        const response = await this.competitiveChampionRatingsListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveChampionRatingsVariableDistributionRaw(
        requestParameters: CompetitiveChampionRatingsVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling competitiveChampionRatingsVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling competitiveChampionRatingsVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling competitiveChampionRatingsVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling competitiveChampionRatingsVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["games"] != null) {
            queryParameters["games"] = requestParameters["games"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["league_group"] != null) {
            queryParameters["league_group"] = requestParameters["league_group"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveChampionRatings/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: CompetitiveChampionRatingsVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response =
            await this.competitiveChampionRatingsVariableDistributionRaw(
                requestParameters,
                initOverrides,
            );
        return await response.value();
    }
}
