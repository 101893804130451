/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";

export interface DownloadsGameReplayRetrieveRequest {
    game_id: string;
    target: string;
}

/**
 *
 */
export class DownloadsApi extends runtime.BaseAPI {
    /**
     */
    async downloadsGameReplayRetrieveRaw(
        requestParameters: DownloadsGameReplayRetrieveRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<{ [key: string]: any }>> {
        if (requestParameters["game_id"] == null) {
            throw new runtime.RequiredError(
                "game_id",
                'Required parameter "game_id" was null or undefined when calling downloadsGameReplayRetrieve().',
            );
        }

        if (requestParameters["target"] == null) {
            throw new runtime.RequiredError(
                "target",
                'Required parameter "target" was null or undefined when calling downloadsGameReplayRetrieve().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["game_id"] != null) {
            queryParameters["game_id"] = requestParameters["game_id"];
        }

        if (requestParameters["target"] != null) {
            queryParameters["target"] = requestParameters["target"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Downloads/GameReplay`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async replay_file(
        requestParameters: DownloadsGameReplayRetrieveRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<{ [key: string]: any }> {
        const response = await this.downloadsGameReplayRetrieveRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
