import React, { useContext, useEffect, useState } from "react";
import FarsightApi from "../../ApiWrapper";
import { Grid, Grow, Stack } from "@mui/material";
import { TeamSelect } from "../selectors/TeamSelect";
import DatePicker from "../selectors/Shared/DateSelect";
import { SideSelect } from "../selectors/YesNoSelector";
import MultipleMetricsSelect from "../selectors/Shared/MetricsSelect";
import global_context from "../../static_vars";
import ItemSelect from "../selectors/Shared/ItemSelect";
import { Depth } from "../selectors/TimestampRangeSelect";
import ChampionBuilds from "../datagrids/shared/ChampionBuilds";
import ChampionFullRunes from "../datagrids/shared/ChampionFullRunes";
import TableDisplayGrid, { rowStyle } from "../datagrids/shared/TableDisplayGrid";
import { useReferentialContext } from "../../Context";
import { GameMetrics } from "../../farsight-api";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";

export default function ChampionRunesFull(props: any) {
    const [depth, setDepth] = useState([1, 3]);

    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [metrics, setMetrics] = useState<GameMetrics[]>(useReferentialContext().defaultMetrics(props.target));

    const initialState = {
        sorting: {
            sortModel: [{ field: "performance", sort: "desc" }],
        },
    } as GridInitialStateCommunity;

    const runes_list = ["keystone", "rune1", "rune2", "rune3", "rune4", "rune5"].slice(depth[0] - 1, depth[1]);

    const request_parameters = {
        ...props.paramObject,
        groupby: runes_list.toString(),
        metrics: metrics.map((metric) => metric.code).toString(),
        ordering: "performance",
        format: "json",
    };
    request_parameters.watermark = JSON.stringify(request_parameters);

    return (
        <Stack sx={{ height: "95%", width:'100%' }} spacing={3}>
            <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                <Stack
                    direction={"row"}
                    sx={{
                        height: "50px",
                        justifyContent: "flex-start",
                        width: "100%",
                    }}
                    spacing={1}
                >
                    <MultipleMetricsSelect values={metrics} updateValue={setMetrics} target={props.target} updateContext={false} />
                    <Depth value={depth} onChange={(event: any, newValue: number[]) => setDepth(newValue)} />
                </Stack>
            </Grow>
            <TableDisplayGrid display_all={false} request={() => api.aggregate(request_parameters)} rowStyle={rowStyle.none} disp_columns={runes_list} sortBy={"performance"} paramObject={request_parameters} metrics={metrics} />
        </Stack>
    );
}
