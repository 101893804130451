import {Box, Grid, Grow, Stack} from "@mui/material";
import OverviewDetailed from "../selectors/OverviewDetailed";
import RoleSelect from "../selectors/RoleSelect";
import React, {useEffect, useState} from "react";
import MultipleMetricsSelect from "../selectors/Shared/MetricsSelect";
import { GameMetrics } from "../../farsight-api";
import { targets } from "../../Context";
import TableDisplayGrid, { rowStyle } from "../datagrids/shared/TableDisplayGrid";
import { useReferentialContext } from "../../Context";
import ChampionsPie from "../Charts/ChampionsPie";

export const roles_to_number: { [target: string]: number } = {
    TOP_LANE: 1,
    JUNGLE: 2,
    MID_LANE: 3,
    BOT_LANE: 4,
    UTILITY: 5,
};

export default function SynergiesMatchups(props: { paramObject: any; target: targets; type: string }) {
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [detailed, setDetailed] = useState(true);
    const [matchupRole, setMatchupRole] = useState<string>(props.paramObject.role);
    const [metrics, setMetrics] = useState<GameMetrics[]>(useReferentialContext().metricsReferential.filter(m => ["performance", "relative_performance", "games", "winrate",'gd15','csd15','kp15'].includes(m.code)));

    const tabs = detailed ? [matchupRole] : ["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY"];
    const c_col = `${props.type === "Matchup" ? "enemy" : "ally"}${roles_to_number[matchupRole]}`;
    const pie_request_parameters = {
        ...props.paramObject,
        groupby: c_col,
        metrics:  ["games", "winrate"].toString(),
        ordering: "performance",
        format: "json",
        include_in_performance: 1
    };
    pie_request_parameters[c_col] = props.paramObject.champion;
    delete pie_request_parameters.champion;

    return (
        <Stack direction={"column"} sx={{ height: "100%", width: "100%" }} spacing={2}>
            <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 2000 }}>
                <Stack
                    direction={"row"}
                    sx={{
                        height: "50px",
                        justifyContent: "left",
                        width: "100%",
                    }}
                    spacing={2}
                >
                    <OverviewDetailed value={detailed} onChange={setDetailed} />
                    {!detailed ? <></> : <MultipleMetricsSelect values={metrics} updateValue={setMetrics} target={props.target} updateContext={false} />}
                    {!detailed ? <></> : <RoleSelect value={matchupRole} onChange={setMatchupRole} label={`${props.type} Role`} />}
                </Stack>
            </Grow>
            <Stack direction={"row"} sx={{ height: "90%", width: "100%" }} spacing={1}>
                <Stack direction={"row"} sx={{ height: "100%", width: "70%" }} spacing={1}>
                {["TOP_LANE", "JUNGLE", "MID_LANE", "BOT_LANE", "UTILITY"].map((mu_role) => {
                    const champ_col = `${props.type === "Matchup" ? "enemy" : "ally"}${roles_to_number[mu_role]}`;

                    const request_parameters = {
                        ...props.paramObject,
                        groupby: champ_col,
                        metrics: detailed ? metrics.map((metric) => metric.code).toString() : ["performance", "relative_performance", "games", "winrate", 'gd15','csd15','kp15'].toString(),
                        ordering: "performance",
                        format: "json",
                    };
                    request_parameters.watermark = JSON.stringify(request_parameters);

                    return (detailed && tabs.includes(mu_role)) || !detailed ? (
                        <SynergyMatchupTab paramObject={props.paramObject} target={props.target} type={props.type} mu_role={mu_role} detailed={detailed} metrics={metrics}/>
                    ) : (
                        <></>
                    );
                })}
                </Stack>
                {detailed ?  <Box sx={{width: '30%'}}>
                    <ChampionsPie target={props.target} paramObject={pie_request_parameters}/>
                </Box> : <></>}
            </Stack>
        </Stack>
    );
}

export function SynergyMatchupTab(props: {paramObject: any, target: targets, type: string, mu_role: string, detailed: boolean, metrics: GameMetrics[]}){
    const [request1Done, setRequest1Done] = useState(false);
    const [request2Done, setRequest2Done] = useState(false);

    const [request1Data, setRequest1Data] = useState([] as any[]);
    const [request2Data, setRequest2Data] = useState({} as any);

    const [finalData, setFinalData] = useState([] as any[]);

    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);

    let metrics_display = ['champion'];
    for(const i in props.metrics){
        const metric = props.metrics[i];
        metrics_display.push(metric.code);
    }
    for(const i in props.metrics){
        const metric = props.metrics[i];
        metrics_display.push(`${props.type}_${metric.code}`);
    }
    const [metricsDisplay, setMetricsDisplay] = useState(metrics_display);


    const champ_col = `${props.type === "Matchup" ? "enemy" : "ally"}${roles_to_number[props.mu_role]}`;
    const request_parameters = {
            ...props.paramObject,
            groupby: champ_col,
            metrics: props.detailed ? props.metrics.map((metric) => metric.code).toString() : ["performance", "relative_performance", "games", "winrate", 'gd15','csd15','kp15'].toString(),
            ordering: "performance",
            format: "json",
            include_in_performance: 1
        };

    const [paramObjectFinal, setParamObjectFinal] = useState(request_parameters);

    useEffect(() => {
        const request_2_parameters = JSON.parse(JSON.stringify(request_parameters));
        delete request_2_parameters.champion;
        const role = request_2_parameters.role;
        delete request_2_parameters.role;
        request_2_parameters[`${props.type === "Matchup" ? "enemy" : "ally"}${roles_to_number[role]}`] = props.paramObject.champion;
        request_2_parameters.index_on = 'champion';
        request_2_parameters.groupby = 'champion';
        request_2_parameters.role = props.mu_role;

        api.aggregate(request_parameters).then(r => {
            console.log(r);
            setRequest1Data(r);
            setRequest1Done(true)
        })
        api.aggregate(request_2_parameters).then(r => {
            console.log(r);
            setRequest2Data(r);
            setRequest2Done(true);
        })

    }, [props.paramObject.watermark, props.type, props.mu_role, props.metrics])

    useEffect(() => {
        if (request1Done && request2Done){
            const new_items = [];
            for (const i in request1Data){
                const champ_stats = request1Data[i];
                const mu_champ = champ_stats[`${props.type === "Matchup" ? "enemy" : "ally"}${roles_to_number[props.mu_role]}`];
                const vs_champ_stats = request2Data[mu_champ] ? request2Data[mu_champ][0] : undefined;
                if(champ_stats && vs_champ_stats){
                    const item = {champion: mu_champ} as any;
                    for(const i in props.metrics){
                        const metric = props.metrics[i];
                        item[metric.code] = champ_stats[metric.code];
                        item[`${props.type}_${metric.code}`] = vs_champ_stats ? vs_champ_stats[metric.code] : undefined;
                    }
                    new_items.push(item);
                }

            }
            setFinalData(new_items);
            setRequest1Done(false);
            setRequest2Done(false);
            setParamObjectFinal({watermark: JSON.stringify(new_items)})
        }},
        [request1Done, request2Done])

    async function get_data(){
        console.log(finalData);
        if( finalData.length >= 0){
            return finalData
        }else{
            return undefined
        }
    }
    console.log(metricsDisplay);

    let columnGroupingModel = [
        {
            groupId: props.paramObject.champion,
            children: [{ field: "champion" }, ...props.metrics.map(m => {
                return {
                    field: m.code
                }})],
            headerClassName: "header",
        },
        {
            groupId: props.type,
            children: props.metrics.map(m => {
                return {
                    field: `${props.type}_${m.code}`
                }}),
            headerClassName: "header",
        },
    ];

    return <TableDisplayGrid
        display_all={false}
        request={() => get_data()}
        rowStyle={rowStyle.none}
        disp_columns={metricsDisplay}
        sortBy={"games"}
        paramObject={paramObjectFinal}
        metrics={[]}
        grouping_model={columnGroupingModel}
    />

}
