type JoinType = 'inner' | 'left' | 'right' | 'full';

export interface JoinedItem<T1, T2> {
    id: any;
    left: T1 | null;
    right: T2 | null;
}

export function joinLists<T1, T2>(
    list1: T1[],
    list2: T2[],
    fieldExtractor1: (item: T1) => any,
    fieldExtractor2: (item: T2) => any,
    joinType: JoinType
): JoinedItem<T1, T2>[] {
    const map1 = new Map<any, T1[]>();
    const map2 = new Map<any, T2[]>();

    // Group items in list1 by the extracted field
    list1.forEach(item => {
        const key = fieldExtractor1(item);
        if (!map1.has(key)) {
            map1.set(key, []);
        }
        map1.get(key)!.push(item);
    });

    // Group items in list2 by the extracted field
    list2.forEach(item => {
        const key = fieldExtractor2(item);
        if (!map2.has(key)) {
            map2.set(key, []);
        }
        map2.get(key)!.push(item);
    });

    const allKeys = Array.from(new Set([...Array.from(map1.keys()), ...Array.from(map2.keys())]));
    const result: JoinedItem<T1, T2>[] = [];

    allKeys.forEach(key => {
        const items1 = map1.get(key) || [];
        const items2 = map2.get(key) || [];

        if (joinType === 'inner' && (items1.length === 0 || items2.length === 0)) {
            return; // Skip keys not present in both lists
        }

        if (joinType === 'left' && items1.length === 0) {
            return; // Skip keys not present in list1
        }

        if (joinType === 'right' && items2.length === 0) {
            return; // Skip keys not present in list2
        }

        if (items1.length === 0) {
            // Handle cases where list1 has no items for the key
            items2.forEach(item2 => {
                result.push({ id: key, left: null, right: item2 });
            });
        } else if (items2.length === 0) {
            // Handle cases where list2 has no items for the key
            items1.forEach(item1 => {
                result.push({ id: key, left: item1, right: null });
            });
        } else {
            // Match all combinations of items from both lists
            items1.forEach(item1 => {
                items2.forEach(item2 => {
                    result.push({ id: key, left: item1, right: item2 });
                });
            });
        }
    });

    return result;
}

export async function fetchAndJoin<T1, T2>(
    request1: () => Promise<T1[]>,
    request2: () => Promise<T2[]>,
    fieldExtractor1: (item: T1) => any,
    fieldExtractor2: (item: T2) => any,
    joinType: JoinType
): Promise<JoinedItem<T1, T2>[]> {
    const [list1, list2] = await Promise.all([request1(), request2()]);
    return joinLists(list1, list2, fieldExtractor1, fieldExtractor2, joinType);
}