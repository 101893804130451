/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    CompetitiveGameSummaries,
    CompetitiveGameSummariesAggregate,
} from "../models/index";

export interface CompetitiveGameSummariesAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    ban_rotation?: string;
    bayes_id?: string;
    champion?: string;
    champion_role_patch?: string;
    date?: string;
    direct_matchup?: string;
    direct_opponent?: string;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game?: string;
    gameidplayer?: string;
    gameplayelements_ptr?: number;
    gameplayevaluationelements_ptr?: number;
    having?: object;
    include_in_lane_performance?: boolean;
    include_in_performance?: boolean;
    index_on?: string;
    is_cp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    lane_swap?: boolean;
    laneperformancescores_ptr?: number;
    league?: string;
    macrostats_ptr?: number;
    match_id?: string;
    metrics?: Array<string>;
    participant_id?: number;
    patch?: string;
    performance?: number;
    phase?: string;
    pick_rotation?: string;
    pickturn?: number;
    player?: string;
    relative_performance?: number;
    role?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    source?: string;
    split?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team1?: string;
    team2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    teamwise_pickturn?: number;
    timelineblobs_ptr?: number;
    win?: boolean;
}

export interface CompetitiveGameSummariesBotlaneMatchupsRequest {
    bottom: string;
    support: string;
    additional_filters?: object;
    aggregates?: Array<string>;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    ban_rotation?: string;
    bayes_id?: string;
    champion?: string;
    champion_role_patch?: string;
    date?: string;
    direct_matchup?: string;
    direct_opponent?: string;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game?: string;
    gameidplayer?: string;
    gameplayelements_ptr?: number;
    gameplayevaluationelements_ptr?: number;
    include_in_lane_performance?: boolean;
    include_in_performance?: boolean;
    is_cp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    lane_swap?: boolean;
    laneperformancescores_ptr?: number;
    league?: string;
    macrostats_ptr?: number;
    match_id?: string;
    metrics?: Array<string>;
    participant_id?: number;
    patch?: string;
    performance?: number;
    phase?: string;
    pick_rotation?: string;
    pickturn?: number;
    player?: string;
    relative_performance?: number;
    role?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    source?: string;
    split?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team1?: string;
    team2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    teamwise_pickturn?: number;
    timelineblobs_ptr?: number;
    win?: boolean;
}

export interface CompetitiveGameSummariesFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    ban_rotation?: string;
    bayes_id?: string;
    champion?: string;
    champion_role_patch?: string;
    date?: string;
    direct_matchup?: string;
    direct_opponent?: string;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game?: string;
    gameidplayer?: string;
    gameplayelements_ptr?: number;
    gameplayevaluationelements_ptr?: number;
    include_in_lane_performance?: boolean;
    include_in_performance?: boolean;
    is_cp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    lane_swap?: boolean;
    laneperformancescores_ptr?: number;
    league?: string;
    macrostats_ptr?: number;
    match_id?: string;
    participant_id?: number;
    patch?: string;
    performance?: number;
    phase?: string;
    pick_rotation?: string;
    pickturn?: number;
    player?: string;
    relative_performance?: number;
    role?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    source?: string;
    split?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team1?: string;
    team2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    teamwise_pickturn?: number;
    timelineblobs_ptr?: number;
    win?: boolean;
}

export interface CompetitiveGameSummariesGetRequest {
    id: string;
}

export interface CompetitiveGameSummariesListRequest {
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    annotations?: Array<string>;
    ban?: string;
    ban_rotation?: string;
    bayes_id?: string;
    champion?: string;
    champion_role_patch?: string;
    date?: string;
    direct_matchup?: string;
    direct_opponent?: string;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game?: string;
    gameidplayer?: string;
    gameplayelements_ptr?: number;
    gameplayevaluationelements_ptr?: number;
    include_in_lane_performance?: boolean;
    include_in_performance?: boolean;
    is_cp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    lane_swap?: boolean;
    laneperformancescores_ptr?: number;
    league?: string;
    macrostats_ptr?: number;
    match_id?: string;
    ordering?: Array<string>;
    participant_id?: number;
    patch?: string;
    performance?: number;
    phase?: string;
    pick_rotation?: string;
    pickturn?: number;
    player?: string;
    relative_performance?: number;
    role?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    source?: string;
    split?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team1?: string;
    team2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    teamwise_pickturn?: number;
    timelineblobs_ptr?: number;
    win?: boolean;
}

export interface CompetitiveGameSummariesVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    ban_rotation?: string;
    bayes_id?: string;
    champion?: string;
    champion_role_patch?: string;
    date?: string;
    direct_matchup?: string;
    direct_opponent?: string;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game?: string;
    gameidplayer?: string;
    gameplayelements_ptr?: number;
    gameplayevaluationelements_ptr?: number;
    include_in_lane_performance?: boolean;
    include_in_performance?: boolean;
    is_cp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    lane_swap?: boolean;
    laneperformancescores_ptr?: number;
    league?: string;
    macrostats_ptr?: number;
    match_id?: string;
    participant_id?: number;
    patch?: string;
    performance?: number;
    phase?: string;
    pick_rotation?: string;
    pickturn?: number;
    player?: string;
    relative_performance?: number;
    role?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    source?: string;
    split?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team1?: string;
    team2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    teamwise_pickturn?: number;
    timelineblobs_ptr?: number;
    win?: boolean;
}

export interface CompetitiveGameSummariesrrComparisonRequest {
    rr_compare: string;
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    ban_rotation?: string;
    bayes_id?: string;
    champion?: string;
    champion_role_patch?: string;
    date?: string;
    direct_matchup?: string;
    direct_opponent?: string;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game?: string;
    gameidplayer?: string;
    gameplayelements_ptr?: number;
    gameplayevaluationelements_ptr?: number;
    include_in_lane_performance?: boolean;
    include_in_performance?: boolean;
    is_cp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    lane_swap?: boolean;
    laneperformancescores_ptr?: number;
    league?: string;
    macrostats_ptr?: number;
    match_id?: string;
    participant_id?: number;
    patch?: string;
    performance?: number;
    phase?: string;
    pick_rotation?: string;
    pickturn?: number;
    player?: string;
    relative_performance?: number;
    role?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    source?: string;
    split?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team1?: string;
    team2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    teamwise_pickturn?: number;
    timelineblobs_ptr?: number;
    win?: boolean;
}

/**
 *
 */
export class CompetitiveGameSummariesApi extends runtime.BaseAPI {
    /**
     */
    async competitiveGameSummariesAggregateRaw(
        requestParameters: CompetitiveGameSummariesAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CompetitiveGameSummariesAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling competitiveGameSummariesAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling competitiveGameSummariesAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["ally1"] != null) {
            queryParameters["ally1"] = requestParameters["ally1"];
        }

        if (requestParameters["ally2"] != null) {
            queryParameters["ally2"] = requestParameters["ally2"];
        }

        if (requestParameters["ally3"] != null) {
            queryParameters["ally3"] = requestParameters["ally3"];
        }

        if (requestParameters["ally4"] != null) {
            queryParameters["ally4"] = requestParameters["ally4"];
        }

        if (requestParameters["ally5"] != null) {
            queryParameters["ally5"] = requestParameters["ally5"];
        }

        if (requestParameters["ban"] != null) {
            queryParameters["ban"] = requestParameters["ban"];
        }

        if (requestParameters["ban_rotation"] != null) {
            queryParameters["ban_rotation"] = requestParameters["ban_rotation"];
        }

        if (requestParameters["bayes_id"] != null) {
            queryParameters["bayes_id"] = requestParameters["bayes_id"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_role_patch"] != null) {
            queryParameters["champion_role_patch"] =
                requestParameters["champion_role_patch"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["direct_matchup"] != null) {
            queryParameters["direct_matchup"] =
                requestParameters["direct_matchup"];
        }

        if (requestParameters["direct_opponent"] != null) {
            queryParameters["direct_opponent"] =
                requestParameters["direct_opponent"];
        }

        if (requestParameters["enemy1"] != null) {
            queryParameters["enemy1"] = requestParameters["enemy1"];
        }

        if (requestParameters["enemy2"] != null) {
            queryParameters["enemy2"] = requestParameters["enemy2"];
        }

        if (requestParameters["enemy3"] != null) {
            queryParameters["enemy3"] = requestParameters["enemy3"];
        }

        if (requestParameters["enemy4"] != null) {
            queryParameters["enemy4"] = requestParameters["enemy4"];
        }

        if (requestParameters["enemy5"] != null) {
            queryParameters["enemy5"] = requestParameters["enemy5"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["gameidplayer"] != null) {
            queryParameters["gameidplayer"] = requestParameters["gameidplayer"];
        }

        if (requestParameters["gameplayelements_ptr"] != null) {
            queryParameters["gameplayelements_ptr"] =
                requestParameters["gameplayelements_ptr"];
        }

        if (requestParameters["gameplayevaluationelements_ptr"] != null) {
            queryParameters["gameplayevaluationelements_ptr"] =
                requestParameters["gameplayevaluationelements_ptr"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["include_in_lane_performance"] != null) {
            queryParameters["include_in_lane_performance"] =
                requestParameters["include_in_lane_performance"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["is_cp"] != null) {
            queryParameters["is_cp"] = requestParameters["is_cp"];
        }

        if (requestParameters["item1"] != null) {
            queryParameters["item1"] = requestParameters["item1"];
        }

        if (requestParameters["item2"] != null) {
            queryParameters["item2"] = requestParameters["item2"];
        }

        if (requestParameters["item3"] != null) {
            queryParameters["item3"] = requestParameters["item3"];
        }

        if (requestParameters["keystone"] != null) {
            queryParameters["keystone"] = requestParameters["keystone"];
        }

        if (requestParameters["lane_swap"] != null) {
            queryParameters["lane_swap"] = requestParameters["lane_swap"];
        }

        if (requestParameters["laneperformancescores_ptr"] != null) {
            queryParameters["laneperformancescores_ptr"] =
                requestParameters["laneperformancescores_ptr"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["macrostats_ptr"] != null) {
            queryParameters["macrostats_ptr"] =
                requestParameters["macrostats_ptr"];
        }

        if (requestParameters["match_id"] != null) {
            queryParameters["match_id"] = requestParameters["match_id"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["participant_id"] != null) {
            queryParameters["participant_id"] =
                requestParameters["participant_id"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["pick_rotation"] != null) {
            queryParameters["pick_rotation"] =
                requestParameters["pick_rotation"];
        }

        if (requestParameters["pickturn"] != null) {
            queryParameters["pickturn"] = requestParameters["pickturn"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["skill_order1"] != null) {
            queryParameters["skill_order1"] = requestParameters["skill_order1"];
        }

        if (requestParameters["skill_order2"] != null) {
            queryParameters["skill_order2"] = requestParameters["skill_order2"];
        }

        if (requestParameters["skill_order3"] != null) {
            queryParameters["skill_order3"] = requestParameters["skill_order3"];
        }

        if (requestParameters["skill_order4"] != null) {
            queryParameters["skill_order4"] = requestParameters["skill_order4"];
        }

        if (requestParameters["skill_order5"] != null) {
            queryParameters["skill_order5"] = requestParameters["skill_order5"];
        }

        if (requestParameters["skill_order6"] != null) {
            queryParameters["skill_order6"] = requestParameters["skill_order6"];
        }

        if (requestParameters["source"] != null) {
            queryParameters["source"] = requestParameters["source"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["subtree"] != null) {
            queryParameters["subtree"] = requestParameters["subtree"];
        }

        if (requestParameters["summoner1"] != null) {
            queryParameters["summoner1"] = requestParameters["summoner1"];
        }

        if (requestParameters["summoner2"] != null) {
            queryParameters["summoner2"] = requestParameters["summoner2"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team_relative_performance"] != null) {
            queryParameters["team_relative_performance"] =
                requestParameters["team_relative_performance"];
        }

        if (
            requestParameters["teamwise_adjusted_relative_performance"] != null
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters["teamwise_adjusted_relative_performance"];
        }

        if (requestParameters["teamwise_pickturn"] != null) {
            queryParameters["teamwise_pickturn"] =
                requestParameters["teamwise_pickturn"];
        }

        if (requestParameters["timelineblobs_ptr"] != null) {
            queryParameters["timelineblobs_ptr"] =
                requestParameters["timelineblobs_ptr"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveGameSummaries/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: CompetitiveGameSummariesAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveGameSummariesAggregate>> {
        const response = await this.competitiveGameSummariesAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveGameSummariesBotlaneMatchupsRaw(
        requestParameters: CompetitiveGameSummariesBotlaneMatchupsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bottom"] == null) {
            throw new runtime.RequiredError(
                "bottom",
                'Required parameter "bottom" was null or undefined when calling competitiveGameSummariesBotlaneMatchups().',
            );
        }

        if (requestParameters["support"] == null) {
            throw new runtime.RequiredError(
                "support",
                'Required parameter "support" was null or undefined when calling competitiveGameSummariesBotlaneMatchups().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["ally1"] != null) {
            queryParameters["ally1"] = requestParameters["ally1"];
        }

        if (requestParameters["ally2"] != null) {
            queryParameters["ally2"] = requestParameters["ally2"];
        }

        if (requestParameters["ally3"] != null) {
            queryParameters["ally3"] = requestParameters["ally3"];
        }

        if (requestParameters["ally4"] != null) {
            queryParameters["ally4"] = requestParameters["ally4"];
        }

        if (requestParameters["ally5"] != null) {
            queryParameters["ally5"] = requestParameters["ally5"];
        }

        if (requestParameters["ban"] != null) {
            queryParameters["ban"] = requestParameters["ban"];
        }

        if (requestParameters["ban_rotation"] != null) {
            queryParameters["ban_rotation"] = requestParameters["ban_rotation"];
        }

        if (requestParameters["bayes_id"] != null) {
            queryParameters["bayes_id"] = requestParameters["bayes_id"];
        }

        if (requestParameters["bottom"] != null) {
            queryParameters["bottom"] = requestParameters["bottom"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_role_patch"] != null) {
            queryParameters["champion_role_patch"] =
                requestParameters["champion_role_patch"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["direct_matchup"] != null) {
            queryParameters["direct_matchup"] =
                requestParameters["direct_matchup"];
        }

        if (requestParameters["direct_opponent"] != null) {
            queryParameters["direct_opponent"] =
                requestParameters["direct_opponent"];
        }

        if (requestParameters["enemy1"] != null) {
            queryParameters["enemy1"] = requestParameters["enemy1"];
        }

        if (requestParameters["enemy2"] != null) {
            queryParameters["enemy2"] = requestParameters["enemy2"];
        }

        if (requestParameters["enemy3"] != null) {
            queryParameters["enemy3"] = requestParameters["enemy3"];
        }

        if (requestParameters["enemy4"] != null) {
            queryParameters["enemy4"] = requestParameters["enemy4"];
        }

        if (requestParameters["enemy5"] != null) {
            queryParameters["enemy5"] = requestParameters["enemy5"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["gameidplayer"] != null) {
            queryParameters["gameidplayer"] = requestParameters["gameidplayer"];
        }

        if (requestParameters["gameplayelements_ptr"] != null) {
            queryParameters["gameplayelements_ptr"] =
                requestParameters["gameplayelements_ptr"];
        }

        if (requestParameters["gameplayevaluationelements_ptr"] != null) {
            queryParameters["gameplayevaluationelements_ptr"] =
                requestParameters["gameplayevaluationelements_ptr"];
        }

        if (requestParameters["include_in_lane_performance"] != null) {
            queryParameters["include_in_lane_performance"] =
                requestParameters["include_in_lane_performance"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["is_cp"] != null) {
            queryParameters["is_cp"] = requestParameters["is_cp"];
        }

        if (requestParameters["item1"] != null) {
            queryParameters["item1"] = requestParameters["item1"];
        }

        if (requestParameters["item2"] != null) {
            queryParameters["item2"] = requestParameters["item2"];
        }

        if (requestParameters["item3"] != null) {
            queryParameters["item3"] = requestParameters["item3"];
        }

        if (requestParameters["keystone"] != null) {
            queryParameters["keystone"] = requestParameters["keystone"];
        }

        if (requestParameters["lane_swap"] != null) {
            queryParameters["lane_swap"] = requestParameters["lane_swap"];
        }

        if (requestParameters["laneperformancescores_ptr"] != null) {
            queryParameters["laneperformancescores_ptr"] =
                requestParameters["laneperformancescores_ptr"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["macrostats_ptr"] != null) {
            queryParameters["macrostats_ptr"] =
                requestParameters["macrostats_ptr"];
        }

        if (requestParameters["match_id"] != null) {
            queryParameters["match_id"] = requestParameters["match_id"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["participant_id"] != null) {
            queryParameters["participant_id"] =
                requestParameters["participant_id"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["pick_rotation"] != null) {
            queryParameters["pick_rotation"] =
                requestParameters["pick_rotation"];
        }

        if (requestParameters["pickturn"] != null) {
            queryParameters["pickturn"] = requestParameters["pickturn"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["skill_order1"] != null) {
            queryParameters["skill_order1"] = requestParameters["skill_order1"];
        }

        if (requestParameters["skill_order2"] != null) {
            queryParameters["skill_order2"] = requestParameters["skill_order2"];
        }

        if (requestParameters["skill_order3"] != null) {
            queryParameters["skill_order3"] = requestParameters["skill_order3"];
        }

        if (requestParameters["skill_order4"] != null) {
            queryParameters["skill_order4"] = requestParameters["skill_order4"];
        }

        if (requestParameters["skill_order5"] != null) {
            queryParameters["skill_order5"] = requestParameters["skill_order5"];
        }

        if (requestParameters["skill_order6"] != null) {
            queryParameters["skill_order6"] = requestParameters["skill_order6"];
        }

        if (requestParameters["source"] != null) {
            queryParameters["source"] = requestParameters["source"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["subtree"] != null) {
            queryParameters["subtree"] = requestParameters["subtree"];
        }

        if (requestParameters["summoner1"] != null) {
            queryParameters["summoner1"] = requestParameters["summoner1"];
        }

        if (requestParameters["summoner2"] != null) {
            queryParameters["summoner2"] = requestParameters["summoner2"];
        }

        if (requestParameters["support"] != null) {
            queryParameters["support"] = requestParameters["support"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team_relative_performance"] != null) {
            queryParameters["team_relative_performance"] =
                requestParameters["team_relative_performance"];
        }

        if (
            requestParameters["teamwise_adjusted_relative_performance"] != null
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters["teamwise_adjusted_relative_performance"];
        }

        if (requestParameters["teamwise_pickturn"] != null) {
            queryParameters["teamwise_pickturn"] =
                requestParameters["teamwise_pickturn"];
        }

        if (requestParameters["timelineblobs_ptr"] != null) {
            queryParameters["timelineblobs_ptr"] =
                requestParameters["timelineblobs_ptr"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveGameSummaries/operations/botlane-matchups`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async botlane_matchups(
        requestParameters: CompetitiveGameSummariesBotlaneMatchupsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.competitiveGameSummariesBotlaneMatchupsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveGameSummariesFieldValuesRaw(
        requestParameters: CompetitiveGameSummariesFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling competitiveGameSummariesFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling competitiveGameSummariesFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["ally1"] != null) {
            queryParameters["ally1"] = requestParameters["ally1"];
        }

        if (requestParameters["ally2"] != null) {
            queryParameters["ally2"] = requestParameters["ally2"];
        }

        if (requestParameters["ally3"] != null) {
            queryParameters["ally3"] = requestParameters["ally3"];
        }

        if (requestParameters["ally4"] != null) {
            queryParameters["ally4"] = requestParameters["ally4"];
        }

        if (requestParameters["ally5"] != null) {
            queryParameters["ally5"] = requestParameters["ally5"];
        }

        if (requestParameters["ban"] != null) {
            queryParameters["ban"] = requestParameters["ban"];
        }

        if (requestParameters["ban_rotation"] != null) {
            queryParameters["ban_rotation"] = requestParameters["ban_rotation"];
        }

        if (requestParameters["bayes_id"] != null) {
            queryParameters["bayes_id"] = requestParameters["bayes_id"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_role_patch"] != null) {
            queryParameters["champion_role_patch"] =
                requestParameters["champion_role_patch"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["direct_matchup"] != null) {
            queryParameters["direct_matchup"] =
                requestParameters["direct_matchup"];
        }

        if (requestParameters["direct_opponent"] != null) {
            queryParameters["direct_opponent"] =
                requestParameters["direct_opponent"];
        }

        if (requestParameters["enemy1"] != null) {
            queryParameters["enemy1"] = requestParameters["enemy1"];
        }

        if (requestParameters["enemy2"] != null) {
            queryParameters["enemy2"] = requestParameters["enemy2"];
        }

        if (requestParameters["enemy3"] != null) {
            queryParameters["enemy3"] = requestParameters["enemy3"];
        }

        if (requestParameters["enemy4"] != null) {
            queryParameters["enemy4"] = requestParameters["enemy4"];
        }

        if (requestParameters["enemy5"] != null) {
            queryParameters["enemy5"] = requestParameters["enemy5"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["gameidplayer"] != null) {
            queryParameters["gameidplayer"] = requestParameters["gameidplayer"];
        }

        if (requestParameters["gameplayelements_ptr"] != null) {
            queryParameters["gameplayelements_ptr"] =
                requestParameters["gameplayelements_ptr"];
        }

        if (requestParameters["gameplayevaluationelements_ptr"] != null) {
            queryParameters["gameplayevaluationelements_ptr"] =
                requestParameters["gameplayevaluationelements_ptr"];
        }

        if (requestParameters["include_in_lane_performance"] != null) {
            queryParameters["include_in_lane_performance"] =
                requestParameters["include_in_lane_performance"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["is_cp"] != null) {
            queryParameters["is_cp"] = requestParameters["is_cp"];
        }

        if (requestParameters["item1"] != null) {
            queryParameters["item1"] = requestParameters["item1"];
        }

        if (requestParameters["item2"] != null) {
            queryParameters["item2"] = requestParameters["item2"];
        }

        if (requestParameters["item3"] != null) {
            queryParameters["item3"] = requestParameters["item3"];
        }

        if (requestParameters["keystone"] != null) {
            queryParameters["keystone"] = requestParameters["keystone"];
        }

        if (requestParameters["lane_swap"] != null) {
            queryParameters["lane_swap"] = requestParameters["lane_swap"];
        }

        if (requestParameters["laneperformancescores_ptr"] != null) {
            queryParameters["laneperformancescores_ptr"] =
                requestParameters["laneperformancescores_ptr"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["macrostats_ptr"] != null) {
            queryParameters["macrostats_ptr"] =
                requestParameters["macrostats_ptr"];
        }

        if (requestParameters["match_id"] != null) {
            queryParameters["match_id"] = requestParameters["match_id"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["participant_id"] != null) {
            queryParameters["participant_id"] =
                requestParameters["participant_id"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["pick_rotation"] != null) {
            queryParameters["pick_rotation"] =
                requestParameters["pick_rotation"];
        }

        if (requestParameters["pickturn"] != null) {
            queryParameters["pickturn"] = requestParameters["pickturn"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["skill_order1"] != null) {
            queryParameters["skill_order1"] = requestParameters["skill_order1"];
        }

        if (requestParameters["skill_order2"] != null) {
            queryParameters["skill_order2"] = requestParameters["skill_order2"];
        }

        if (requestParameters["skill_order3"] != null) {
            queryParameters["skill_order3"] = requestParameters["skill_order3"];
        }

        if (requestParameters["skill_order4"] != null) {
            queryParameters["skill_order4"] = requestParameters["skill_order4"];
        }

        if (requestParameters["skill_order5"] != null) {
            queryParameters["skill_order5"] = requestParameters["skill_order5"];
        }

        if (requestParameters["skill_order6"] != null) {
            queryParameters["skill_order6"] = requestParameters["skill_order6"];
        }

        if (requestParameters["source"] != null) {
            queryParameters["source"] = requestParameters["source"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["subtree"] != null) {
            queryParameters["subtree"] = requestParameters["subtree"];
        }

        if (requestParameters["summoner1"] != null) {
            queryParameters["summoner1"] = requestParameters["summoner1"];
        }

        if (requestParameters["summoner2"] != null) {
            queryParameters["summoner2"] = requestParameters["summoner2"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team_relative_performance"] != null) {
            queryParameters["team_relative_performance"] =
                requestParameters["team_relative_performance"];
        }

        if (
            requestParameters["teamwise_adjusted_relative_performance"] != null
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters["teamwise_adjusted_relative_performance"];
        }

        if (requestParameters["teamwise_pickturn"] != null) {
            queryParameters["teamwise_pickturn"] =
                requestParameters["teamwise_pickturn"];
        }

        if (requestParameters["timelineblobs_ptr"] != null) {
            queryParameters["timelineblobs_ptr"] =
                requestParameters["timelineblobs_ptr"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveGameSummaries/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: CompetitiveGameSummariesFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.competitiveGameSummariesFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveGameSummariesGetRaw(
        requestParameters: CompetitiveGameSummariesGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CompetitiveGameSummaries>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling competitiveGameSummariesGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveGameSummaries/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: CompetitiveGameSummariesGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CompetitiveGameSummaries> {
        const response = await this.competitiveGameSummariesGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveGameSummariesListRaw(
        requestParameters: CompetitiveGameSummariesListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CompetitiveGameSummaries>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["ally1"] != null) {
            queryParameters["ally1"] = requestParameters["ally1"];
        }

        if (requestParameters["ally2"] != null) {
            queryParameters["ally2"] = requestParameters["ally2"];
        }

        if (requestParameters["ally3"] != null) {
            queryParameters["ally3"] = requestParameters["ally3"];
        }

        if (requestParameters["ally4"] != null) {
            queryParameters["ally4"] = requestParameters["ally4"];
        }

        if (requestParameters["ally5"] != null) {
            queryParameters["ally5"] = requestParameters["ally5"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["ban"] != null) {
            queryParameters["ban"] = requestParameters["ban"];
        }

        if (requestParameters["ban_rotation"] != null) {
            queryParameters["ban_rotation"] = requestParameters["ban_rotation"];
        }

        if (requestParameters["bayes_id"] != null) {
            queryParameters["bayes_id"] = requestParameters["bayes_id"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_role_patch"] != null) {
            queryParameters["champion_role_patch"] =
                requestParameters["champion_role_patch"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["direct_matchup"] != null) {
            queryParameters["direct_matchup"] =
                requestParameters["direct_matchup"];
        }

        if (requestParameters["direct_opponent"] != null) {
            queryParameters["direct_opponent"] =
                requestParameters["direct_opponent"];
        }

        if (requestParameters["enemy1"] != null) {
            queryParameters["enemy1"] = requestParameters["enemy1"];
        }

        if (requestParameters["enemy2"] != null) {
            queryParameters["enemy2"] = requestParameters["enemy2"];
        }

        if (requestParameters["enemy3"] != null) {
            queryParameters["enemy3"] = requestParameters["enemy3"];
        }

        if (requestParameters["enemy4"] != null) {
            queryParameters["enemy4"] = requestParameters["enemy4"];
        }

        if (requestParameters["enemy5"] != null) {
            queryParameters["enemy5"] = requestParameters["enemy5"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["gameidplayer"] != null) {
            queryParameters["gameidplayer"] = requestParameters["gameidplayer"];
        }

        if (requestParameters["gameplayelements_ptr"] != null) {
            queryParameters["gameplayelements_ptr"] =
                requestParameters["gameplayelements_ptr"];
        }

        if (requestParameters["gameplayevaluationelements_ptr"] != null) {
            queryParameters["gameplayevaluationelements_ptr"] =
                requestParameters["gameplayevaluationelements_ptr"];
        }

        if (requestParameters["include_in_lane_performance"] != null) {
            queryParameters["include_in_lane_performance"] =
                requestParameters["include_in_lane_performance"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["is_cp"] != null) {
            queryParameters["is_cp"] = requestParameters["is_cp"];
        }

        if (requestParameters["item1"] != null) {
            queryParameters["item1"] = requestParameters["item1"];
        }

        if (requestParameters["item2"] != null) {
            queryParameters["item2"] = requestParameters["item2"];
        }

        if (requestParameters["item3"] != null) {
            queryParameters["item3"] = requestParameters["item3"];
        }

        if (requestParameters["keystone"] != null) {
            queryParameters["keystone"] = requestParameters["keystone"];
        }

        if (requestParameters["lane_swap"] != null) {
            queryParameters["lane_swap"] = requestParameters["lane_swap"];
        }

        if (requestParameters["laneperformancescores_ptr"] != null) {
            queryParameters["laneperformancescores_ptr"] =
                requestParameters["laneperformancescores_ptr"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["macrostats_ptr"] != null) {
            queryParameters["macrostats_ptr"] =
                requestParameters["macrostats_ptr"];
        }

        if (requestParameters["match_id"] != null) {
            queryParameters["match_id"] = requestParameters["match_id"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["participant_id"] != null) {
            queryParameters["participant_id"] =
                requestParameters["participant_id"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["pick_rotation"] != null) {
            queryParameters["pick_rotation"] =
                requestParameters["pick_rotation"];
        }

        if (requestParameters["pickturn"] != null) {
            queryParameters["pickturn"] = requestParameters["pickturn"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["skill_order1"] != null) {
            queryParameters["skill_order1"] = requestParameters["skill_order1"];
        }

        if (requestParameters["skill_order2"] != null) {
            queryParameters["skill_order2"] = requestParameters["skill_order2"];
        }

        if (requestParameters["skill_order3"] != null) {
            queryParameters["skill_order3"] = requestParameters["skill_order3"];
        }

        if (requestParameters["skill_order4"] != null) {
            queryParameters["skill_order4"] = requestParameters["skill_order4"];
        }

        if (requestParameters["skill_order5"] != null) {
            queryParameters["skill_order5"] = requestParameters["skill_order5"];
        }

        if (requestParameters["skill_order6"] != null) {
            queryParameters["skill_order6"] = requestParameters["skill_order6"];
        }

        if (requestParameters["source"] != null) {
            queryParameters["source"] = requestParameters["source"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["subtree"] != null) {
            queryParameters["subtree"] = requestParameters["subtree"];
        }

        if (requestParameters["summoner1"] != null) {
            queryParameters["summoner1"] = requestParameters["summoner1"];
        }

        if (requestParameters["summoner2"] != null) {
            queryParameters["summoner2"] = requestParameters["summoner2"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team_relative_performance"] != null) {
            queryParameters["team_relative_performance"] =
                requestParameters["team_relative_performance"];
        }

        if (
            requestParameters["teamwise_adjusted_relative_performance"] != null
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters["teamwise_adjusted_relative_performance"];
        }

        if (requestParameters["teamwise_pickturn"] != null) {
            queryParameters["teamwise_pickturn"] =
                requestParameters["teamwise_pickturn"];
        }

        if (requestParameters["timelineblobs_ptr"] != null) {
            queryParameters["timelineblobs_ptr"] =
                requestParameters["timelineblobs_ptr"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveGameSummaries/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: CompetitiveGameSummariesListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveGameSummaries>> {
        const response = await this.competitiveGameSummariesListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveGameSummariesVariableDistributionRaw(
        requestParameters: CompetitiveGameSummariesVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling competitiveGameSummariesVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling competitiveGameSummariesVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling competitiveGameSummariesVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling competitiveGameSummariesVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["ally1"] != null) {
            queryParameters["ally1"] = requestParameters["ally1"];
        }

        if (requestParameters["ally2"] != null) {
            queryParameters["ally2"] = requestParameters["ally2"];
        }

        if (requestParameters["ally3"] != null) {
            queryParameters["ally3"] = requestParameters["ally3"];
        }

        if (requestParameters["ally4"] != null) {
            queryParameters["ally4"] = requestParameters["ally4"];
        }

        if (requestParameters["ally5"] != null) {
            queryParameters["ally5"] = requestParameters["ally5"];
        }

        if (requestParameters["ban"] != null) {
            queryParameters["ban"] = requestParameters["ban"];
        }

        if (requestParameters["ban_rotation"] != null) {
            queryParameters["ban_rotation"] = requestParameters["ban_rotation"];
        }

        if (requestParameters["bayes_id"] != null) {
            queryParameters["bayes_id"] = requestParameters["bayes_id"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_role_patch"] != null) {
            queryParameters["champion_role_patch"] =
                requestParameters["champion_role_patch"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["direct_matchup"] != null) {
            queryParameters["direct_matchup"] =
                requestParameters["direct_matchup"];
        }

        if (requestParameters["direct_opponent"] != null) {
            queryParameters["direct_opponent"] =
                requestParameters["direct_opponent"];
        }

        if (requestParameters["enemy1"] != null) {
            queryParameters["enemy1"] = requestParameters["enemy1"];
        }

        if (requestParameters["enemy2"] != null) {
            queryParameters["enemy2"] = requestParameters["enemy2"];
        }

        if (requestParameters["enemy3"] != null) {
            queryParameters["enemy3"] = requestParameters["enemy3"];
        }

        if (requestParameters["enemy4"] != null) {
            queryParameters["enemy4"] = requestParameters["enemy4"];
        }

        if (requestParameters["enemy5"] != null) {
            queryParameters["enemy5"] = requestParameters["enemy5"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["gameidplayer"] != null) {
            queryParameters["gameidplayer"] = requestParameters["gameidplayer"];
        }

        if (requestParameters["gameplayelements_ptr"] != null) {
            queryParameters["gameplayelements_ptr"] =
                requestParameters["gameplayelements_ptr"];
        }

        if (requestParameters["gameplayevaluationelements_ptr"] != null) {
            queryParameters["gameplayevaluationelements_ptr"] =
                requestParameters["gameplayevaluationelements_ptr"];
        }

        if (requestParameters["include_in_lane_performance"] != null) {
            queryParameters["include_in_lane_performance"] =
                requestParameters["include_in_lane_performance"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["is_cp"] != null) {
            queryParameters["is_cp"] = requestParameters["is_cp"];
        }

        if (requestParameters["item1"] != null) {
            queryParameters["item1"] = requestParameters["item1"];
        }

        if (requestParameters["item2"] != null) {
            queryParameters["item2"] = requestParameters["item2"];
        }

        if (requestParameters["item3"] != null) {
            queryParameters["item3"] = requestParameters["item3"];
        }

        if (requestParameters["keystone"] != null) {
            queryParameters["keystone"] = requestParameters["keystone"];
        }

        if (requestParameters["lane_swap"] != null) {
            queryParameters["lane_swap"] = requestParameters["lane_swap"];
        }

        if (requestParameters["laneperformancescores_ptr"] != null) {
            queryParameters["laneperformancescores_ptr"] =
                requestParameters["laneperformancescores_ptr"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["macrostats_ptr"] != null) {
            queryParameters["macrostats_ptr"] =
                requestParameters["macrostats_ptr"];
        }

        if (requestParameters["match_id"] != null) {
            queryParameters["match_id"] = requestParameters["match_id"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["participant_id"] != null) {
            queryParameters["participant_id"] =
                requestParameters["participant_id"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["pick_rotation"] != null) {
            queryParameters["pick_rotation"] =
                requestParameters["pick_rotation"];
        }

        if (requestParameters["pickturn"] != null) {
            queryParameters["pickturn"] = requestParameters["pickturn"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["skill_order1"] != null) {
            queryParameters["skill_order1"] = requestParameters["skill_order1"];
        }

        if (requestParameters["skill_order2"] != null) {
            queryParameters["skill_order2"] = requestParameters["skill_order2"];
        }

        if (requestParameters["skill_order3"] != null) {
            queryParameters["skill_order3"] = requestParameters["skill_order3"];
        }

        if (requestParameters["skill_order4"] != null) {
            queryParameters["skill_order4"] = requestParameters["skill_order4"];
        }

        if (requestParameters["skill_order5"] != null) {
            queryParameters["skill_order5"] = requestParameters["skill_order5"];
        }

        if (requestParameters["skill_order6"] != null) {
            queryParameters["skill_order6"] = requestParameters["skill_order6"];
        }

        if (requestParameters["source"] != null) {
            queryParameters["source"] = requestParameters["source"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["subtree"] != null) {
            queryParameters["subtree"] = requestParameters["subtree"];
        }

        if (requestParameters["summoner1"] != null) {
            queryParameters["summoner1"] = requestParameters["summoner1"];
        }

        if (requestParameters["summoner2"] != null) {
            queryParameters["summoner2"] = requestParameters["summoner2"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team_relative_performance"] != null) {
            queryParameters["team_relative_performance"] =
                requestParameters["team_relative_performance"];
        }

        if (
            requestParameters["teamwise_adjusted_relative_performance"] != null
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters["teamwise_adjusted_relative_performance"];
        }

        if (requestParameters["teamwise_pickturn"] != null) {
            queryParameters["teamwise_pickturn"] =
                requestParameters["teamwise_pickturn"];
        }

        if (requestParameters["timelineblobs_ptr"] != null) {
            queryParameters["timelineblobs_ptr"] =
                requestParameters["timelineblobs_ptr"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveGameSummaries/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: CompetitiveGameSummariesVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response =
            await this.competitiveGameSummariesVariableDistributionRaw(
                requestParameters,
                initOverrides,
            );
        return await response.value();
    }

    /**
     */
    async competitiveGameSummariesrrComparisonRaw(
        requestParameters: CompetitiveGameSummariesrrComparisonRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["rr_compare"] == null) {
            throw new runtime.RequiredError(
                "rr_compare",
                'Required parameter "rr_compare" was null or undefined when calling competitiveGameSummariesrrComparison().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["ally1"] != null) {
            queryParameters["ally1"] = requestParameters["ally1"];
        }

        if (requestParameters["ally2"] != null) {
            queryParameters["ally2"] = requestParameters["ally2"];
        }

        if (requestParameters["ally3"] != null) {
            queryParameters["ally3"] = requestParameters["ally3"];
        }

        if (requestParameters["ally4"] != null) {
            queryParameters["ally4"] = requestParameters["ally4"];
        }

        if (requestParameters["ally5"] != null) {
            queryParameters["ally5"] = requestParameters["ally5"];
        }

        if (requestParameters["ban"] != null) {
            queryParameters["ban"] = requestParameters["ban"];
        }

        if (requestParameters["ban_rotation"] != null) {
            queryParameters["ban_rotation"] = requestParameters["ban_rotation"];
        }

        if (requestParameters["bayes_id"] != null) {
            queryParameters["bayes_id"] = requestParameters["bayes_id"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_role_patch"] != null) {
            queryParameters["champion_role_patch"] =
                requestParameters["champion_role_patch"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["direct_matchup"] != null) {
            queryParameters["direct_matchup"] =
                requestParameters["direct_matchup"];
        }

        if (requestParameters["direct_opponent"] != null) {
            queryParameters["direct_opponent"] =
                requestParameters["direct_opponent"];
        }

        if (requestParameters["enemy1"] != null) {
            queryParameters["enemy1"] = requestParameters["enemy1"];
        }

        if (requestParameters["enemy2"] != null) {
            queryParameters["enemy2"] = requestParameters["enemy2"];
        }

        if (requestParameters["enemy3"] != null) {
            queryParameters["enemy3"] = requestParameters["enemy3"];
        }

        if (requestParameters["enemy4"] != null) {
            queryParameters["enemy4"] = requestParameters["enemy4"];
        }

        if (requestParameters["enemy5"] != null) {
            queryParameters["enemy5"] = requestParameters["enemy5"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["gameidplayer"] != null) {
            queryParameters["gameidplayer"] = requestParameters["gameidplayer"];
        }

        if (requestParameters["gameplayelements_ptr"] != null) {
            queryParameters["gameplayelements_ptr"] =
                requestParameters["gameplayelements_ptr"];
        }

        if (requestParameters["gameplayevaluationelements_ptr"] != null) {
            queryParameters["gameplayevaluationelements_ptr"] =
                requestParameters["gameplayevaluationelements_ptr"];
        }

        if (requestParameters["include_in_lane_performance"] != null) {
            queryParameters["include_in_lane_performance"] =
                requestParameters["include_in_lane_performance"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["is_cp"] != null) {
            queryParameters["is_cp"] = requestParameters["is_cp"];
        }

        if (requestParameters["item1"] != null) {
            queryParameters["item1"] = requestParameters["item1"];
        }

        if (requestParameters["item2"] != null) {
            queryParameters["item2"] = requestParameters["item2"];
        }

        if (requestParameters["item3"] != null) {
            queryParameters["item3"] = requestParameters["item3"];
        }

        if (requestParameters["keystone"] != null) {
            queryParameters["keystone"] = requestParameters["keystone"];
        }

        if (requestParameters["lane_swap"] != null) {
            queryParameters["lane_swap"] = requestParameters["lane_swap"];
        }

        if (requestParameters["laneperformancescores_ptr"] != null) {
            queryParameters["laneperformancescores_ptr"] =
                requestParameters["laneperformancescores_ptr"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["macrostats_ptr"] != null) {
            queryParameters["macrostats_ptr"] =
                requestParameters["macrostats_ptr"];
        }

        if (requestParameters["match_id"] != null) {
            queryParameters["match_id"] = requestParameters["match_id"];
        }

        if (requestParameters["participant_id"] != null) {
            queryParameters["participant_id"] =
                requestParameters["participant_id"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["pick_rotation"] != null) {
            queryParameters["pick_rotation"] =
                requestParameters["pick_rotation"];
        }

        if (requestParameters["pickturn"] != null) {
            queryParameters["pickturn"] = requestParameters["pickturn"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["rr_compare"] != null) {
            queryParameters["rr_compare"] = requestParameters["rr_compare"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["skill_order1"] != null) {
            queryParameters["skill_order1"] = requestParameters["skill_order1"];
        }

        if (requestParameters["skill_order2"] != null) {
            queryParameters["skill_order2"] = requestParameters["skill_order2"];
        }

        if (requestParameters["skill_order3"] != null) {
            queryParameters["skill_order3"] = requestParameters["skill_order3"];
        }

        if (requestParameters["skill_order4"] != null) {
            queryParameters["skill_order4"] = requestParameters["skill_order4"];
        }

        if (requestParameters["skill_order5"] != null) {
            queryParameters["skill_order5"] = requestParameters["skill_order5"];
        }

        if (requestParameters["skill_order6"] != null) {
            queryParameters["skill_order6"] = requestParameters["skill_order6"];
        }

        if (requestParameters["source"] != null) {
            queryParameters["source"] = requestParameters["source"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["subtree"] != null) {
            queryParameters["subtree"] = requestParameters["subtree"];
        }

        if (requestParameters["summoner1"] != null) {
            queryParameters["summoner1"] = requestParameters["summoner1"];
        }

        if (requestParameters["summoner2"] != null) {
            queryParameters["summoner2"] = requestParameters["summoner2"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["team_relative_performance"] != null) {
            queryParameters["team_relative_performance"] =
                requestParameters["team_relative_performance"];
        }

        if (
            requestParameters["teamwise_adjusted_relative_performance"] != null
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters["teamwise_adjusted_relative_performance"];
        }

        if (requestParameters["teamwise_pickturn"] != null) {
            queryParameters["teamwise_pickturn"] =
                requestParameters["teamwise_pickturn"];
        }

        if (requestParameters["timelineblobs_ptr"] != null) {
            queryParameters["timelineblobs_ptr"] =
                requestParameters["timelineblobs_ptr"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveGameSummaries/operations/rr_details`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async rr_comparison(
        requestParameters: CompetitiveGameSummariesrrComparisonRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.competitiveGameSummariesrrComparisonRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
