import {Grow, Stack, Switch, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {Bar} from "react-chartjs-2";
import React, {useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import {targets, useReferentialContext} from "../../Context";
import GenericBarChart from "./GenericBars";
import GenericRadarChart, {GenericRadarChartNivo} from "./GenericRadar";
import Button from "@mui/material/Button";
import {BarChart} from "@mui/x-charts";
import html2canvas from "html2canvas";
import {useThemeContext} from "../../themeContext";


export default function MetricRadar(props: {paramObjectMain: any, paramObjectReference: any, target: targets, metric: string}){
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [mainData, setMainData] = useState([] as any[]);
    const [refData, setRefData] = useState([] as any[]);
    const [fullData, setFullData] = useState([] as any[]);

    const [mainDone, setMainDone] = useState(false);
    const [refDone, setRefDone] = useState(false);
    const [radar, setRadar] = useState(true);

    const chartRef = useRef(null);

    const theme = useThemeContext();

    const handleCapture = () => {
      if (chartRef.current) {
          // Ensure that the DataGrid is fully rendered before capturing
          // await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust delay if necessary


          // @ts-ignore
          html2canvas(chartRef.current, {
              logging: true,
              useCORS: true,
              allowTaint: true,
              backgroundColor: theme.mode === 'dark' ? '#000000' : '#F7F4EF',
          }).then((canvas) => {
              canvas.toBlob(async (blob) => {
              try {
                await navigator.clipboard.write([
                    // @ts-ignore
                  new ClipboardItem({ 'image/png': blob })
                ]);
                // alert('Image copied to clipboard!');
              } catch (err) {
                console.error('Failed to copy image to clipboard: ', err);
                alert('Failed to copy image to clipboard')
              }
            }, 'image/png');
          })
      }
    }

    useEffect(() => {
        api.aggregate({
            ...props.paramObjectMain,
            groupby: ['player'],
            aggregates: ['participant_id__avg'],
            ordering: ['participant_id__avg'],
            metrics: [props.metric]
        }).then(r => {
            console.log(r);
            setMainData(r);
            setMainDone(true);
        });

        api.aggregate({
            ...props.paramObjectReference,
            groupby: ['role'],
            aggregates: ['participant_id__avg'],
            ordering: ['participant_id__avg'],
            metrics: [props.metric]
        }).then(r => {
            setRefData(r);
            setRefDone(true);
        });
    }, [props.paramObjectMain.watermark, props.paramObjectReference.watermark, props.target])

    useEffect(() => {
        if(mainDone && refDone){
            const full_data = mainData.map((main, index) => {
                let obj : any = {player: main.player};
                obj[`${props.metric}`] = main[props.metric];
                obj[`Matchup ${props.metric}`] = refData[index] ? refData[index][props.metric] : 0;
                obj['label'] = `${main.player} \n ${(main[props.metric]*100).toFixed(1)}%`
                return obj
            })

            setFullData(full_data);
            setMainDone(false);
            setRefDone(false);
            console.log(fullData.map(o => {
          return {data: [o[`${props.metric}`], o[`Matchup ${props.metric}`]]}
      }))
        }
    }, [mainDone, refDone])

    const labels = [
        {id: `${props.metric}`, label: 'Player'},
        {id: `Matchup ${props.metric}`, label: 'Opponents'},
    ]

    return (<Box sx={{height:'100%', width:'100%'}}>
            <Stack direction={'row'} sx={{width:'100%', alignItems:'center', justifyContent:'center', display:'flex'}}>
                <Switch value={radar} onChange={() => setRadar(!radar)}/>
                <Typography>{props.target.charAt(0).toUpperCase() + props.target.slice(1).toLowerCase()} {props.metric.includes('pre') ? 'Forward% @15' : 'Forward% @@15'}</Typography>
            </Stack>
            <Box sx={{height:'100%', width:'100%'}} ref={chartRef}>
            {radar ? <GenericRadarChartNivo data={fullData} mainLabel={'Team'}
                                    labels={labels}
                                    valueKey={props.metric}
                                    vertical={true}
                                    comparisonValueKey={`Matchup ${props.metric}`}
            /> :
                <BarChart barLabel={(item, context) => {
    return item.value ? `${(item.value*100).toFixed(1)}%` : '';
  }}
      series={[
          {data: fullData.map(o => o[`${props.metric}`]), label: 'Player FWD%'},
          {data: fullData.map(o => o[`Matchup ${props.metric}`]), label: 'Matchup FWD%'}
      ]}
      yAxis={[{ data: fullData.map(o => o.player), scaleType: 'band', max:0.6 }]}
      layout={"horizontal"}
      sx={{width:'100%', height:'100%'}}
    margin={{
    left: 80,
  }}
                />}</Box>
            <Button variant={'outlined'} onClick={() => handleCapture()}>PNG</Button>
        </Box>
    );
}