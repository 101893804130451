import {Stack, styled, Typography} from "@mui/material";


export const Title = styled(Typography)(({ theme }) => ({textAlign:'center', fontSize:'1.5em', fontWeight:'bold'}))


export const StatLabel = styled(Typography)(({ theme }) => ({textAlign:'left', fontSize:'1em', fontWeight:'bold'}));


export function StatInStack(props: {label: string, value: number, format?: (value: number) => string | number}) {
    return <Stack direction={'row'} sx={{align:'space-between', width:'100%', display:'flex', justify:'space-between'}}>
        <StatLabel sx={{width: "50%", justifyContent: "left", display: "flex"}}>{props.label}</StatLabel>
        <StatLabel sx={{width: "50%", justifyContent: "right", display: "flex"}}>{props.value ? (props.format ? props.format(props.value) : props.value.toFixed(1) ) : '.'}</StatLabel>
    </Stack>
}
