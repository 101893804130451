import React, { useEffect, useState } from "react";
import FarsightApi from "../../ApiWrapper";
import Box from "@mui/material/Box";
import {Pie} from "react-chartjs-2";
import {targets, useReferentialContext} from "../../Context";
import {Chart, ArcElement, Tooltip, Legend, ChartOptions} from 'chart.js';
import {
    HighlightScope,
    pieArcLabelClasses,
    PieChart,
    PiePlot, PieSeriesType,
    ResponsiveChartContainer,
    useDrawingArea
} from "@mui/x-charts";
import {styled} from "@mui/material";

Chart.register(ArcElement, Tooltip, Legend);


// export default function ChampionsPie(props: {paramObject: any, target: targets}) {
//     const [rows, setRows] = useState([] as any);
//     const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
//
//     useEffect(() => {
//         const request_parameters = {
//             ...props.paramObject,
//             groupby: 'champion',
//             metrics: ['games'],
//             ordering: "games",
//             format: "json",
//             aggregates: ['win__sum']
//         };
//         api.aggregate(request_parameters).then(result => setRows(result))
//     }, [props]);
//
//     const options = {
//         responsive: true,
//         plugins: {
//             legend: {
//                 position: "left" as const,
//                 display: false
//             },
//             tooltip:{
//                 callbacks:{
//                     label(tooltipItem: any): string | string[] | void {
//                         console.log(tooltipItem);
//                         const dataset = data.datasets[tooltipItem.datasetIndex];
//                         const getValue = dataset.data[tooltipItem.dataIndex];
//                         const label = data.labels[tooltipItem.dataIndex];
//                         return `${label}: ${getValue}`;
//                     },
//                 },
//             },
//             labels: {
//                 render: 'getValue'
//             }
//         },
//         lineTension: 0.3,
//     } as ChartOptions;
//
//     const data = {
//         labels: rows.map((row: any) => row.champion),
//         datasets: [
//             {
//                 label: "Games",
//                 data: rows.map((row: any) => row.games),
//                 backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)", "rgba(75, 192, 192, 0.2)", "rgba(153, 102, 255, 0.2)", "rgba(255, 159, 64, 0.2)"],
//                 borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
//                 borderWidth: 1,
//             },
//             {
//                 label: "Wins",
//                 data: rows.map((row: any) => row.win__sum),
//                 backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)", "rgba(75, 192, 192, 0.2)", "rgba(153, 102, 255, 0.2)", "rgba(255, 159, 64, 0.2)"],
//                 borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
//                 borderWidth: 1,
//             },
//         ],
//     };
//
//     return (
//         <Box component={"fieldset"}
//                sx={{
//                    borderRadius: 3,
//                    height: "100%",
//                    width: "100%",
//                    alignItems: "center",
//                    justifyContent: "center",
//                    display: "flex",
//                    padding: 3
//                }}
//         >
//             <legend>Champion Pool</legend>
//             <Pie options={options} data={data} height={"100%"} width={"100%"}/>
//         </Box>
//     );
// }




export default function ChampionsPie(props: {paramObject: any, target: targets}) {
    const [data, setData] = useState([] as any);
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [highlighted, setHighlighted] = useState('item');
    const [faded, setFaded] = useState('global');

    useEffect(() => {
        const request_parameters = {
            ...props.paramObject,
            groupby: 'champion',
            metrics: ['games'],
            ordering: "games",
            format: "json",
            aggregates: ['win__sum']
        };
        api.aggregate(request_parameters).then(result => setData(result))
    }, [props]);

    const renderImagesInLayer = ({series,}: {series: Array<PieSeriesType & { arcs: { centroid: [number, number] }[] }>;}) => {
        console.log(series)
        const images = series[2].data.map((item, index) => {
        const imageSize = 24; // Adjust image size
        const [x, y] = series[2].arcs[index].centroid;
        const imageUrl = 'https://res.cloudinary.com/xenesis/image/upload/c_scale,h_40,w_40/v1/championAssets/Azir.png'; // Placeholder image

          return (
            <image
              // key={item.label}
              href={imageUrl}
              x={x - imageSize / 2}
              y={y - imageSize / 2}
              width={imageSize}
              height={imageSize}
              // alt={item.label}
            />
          );
        });

    return <g>{images}</g>;
  };

    return (
            <Box sx={{width:'100%', height:'100%'}}>
                <PieChart
                    series={[
                        {
                            type: 'pie',
                            innerRadius: 70,
                            outerRadius: 120,
                            data: data.map((e: any) => {return {label: `${e.champion}`, value: e.win__sum}}),
                            highlighted: { additionalRadius: 10 },
                            highlightScope: {
                                highlighted,
                                faded,
                            } as HighlightScope,
                            paddingAngle: 2,
                            cornerRadius: 5,
                            arcLabel: (item) => `${item.value} W.`,
                            arcLabelMinAngle: 20,
                        },
                        {
                            type: 'pie',
                            innerRadius: 140,
                            outerRadius: 170,
                            data: data.map((e: any) => {return {label: `${e.champion}`, value: e.games}}),
                            highlighted: { additionalRadius: 10 },
                            highlightScope: {
                                highlighted,
                                faded,
                            } as HighlightScope,
                            paddingAngle: 2,
                            cornerRadius: 5,
                            arcLabel: (item) => `${item.value} G.`,
                            arcLabelMinAngle: 20,
                        },
                        {
                            type: 'pie',
                            innerRadius: 165,
                            outerRadius: 220,
                            data: data.map((e: any) => {return {label: `${e.champion}`, value: e.games}}),
                            highlighted: { additionalRadius: 10 },
                            highlightScope: {
                                highlighted,
                                faded,
                            } as HighlightScope,
                            paddingAngle: 2,
                            cornerRadius: 5,
                            arcLabel: (item) => `${item.label}`,
                            arcLabelMinAngle: 20,
                        },
                    ]}
                    margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    // renderInLayer={{renderImagesInLayer}}
                    slotProps={{
                        legend: {
                            hidden: true,
                            itemMarkWidth: 20,
                            itemMarkHeight: 2,
                            markGap: 5,
                            itemGap: 10,
                            labelStyle: {
                                fontSize: 14,
                                fill: 'blue',
                            },
                        },

                    }}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                            fill: 'white',
                            fontWeight: 'bold',
                        },
                    }}
                ><PieCenterLabel>Champions</PieCenterLabel></PieChart>
            </Box>
    );
}

const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
    whiteSpace: "pre",
    fontWeight:'bold'
}));

export function PieCenterLabel({ children }: { children: React.ReactNode }) {
    const { width, height, left, top } = useDrawingArea();

    // Split the text by `\n` to get individual lines
    const lines = typeof children === 'string' ? children.split('\n') : [];

    return (
        <StyledText x={left + width / 2} y={(top + height / 2) - (5 * lines.length)}>
            {lines.map((line, index) => (
                <tspan key={index} x={left + width / 2} dy={index === 0 ? '0' : '1.2em'}>
                    {line}
                </tspan>
            ))}
        </StyledText>
    );
}


