


export class Formatters {
    percent: (v: number) => string;
    integer: (v: number) => string;

    constructor() {
        this.percent = (v: number) => `${(v*100).toFixed(1)}%`
        this.integer = (v: number) => `${v}`
    }
}

const formatters = new Formatters();
export default formatters;