import React, {useEffect, useState} from "react";
import Layout from "./components/layout/layout";
import {CssBaseline, Grid, Theme} from "@mui/material";
import TabPanel from "./components/tabs_switch/tabs_panel";
import LoginPage from "./login";
import CompetitiveChampionsRatings
    from "./page/Competitive/1_Metagame/1_ChampionsRatingsCompetitive/CompetitiveChampionsRatings";
import BotlaneMatchups from "./page/Competitive/1_Metagame/4_MetaCountersCompetitive/Botlane Matchups";
import MetaBreakersCompetitive from "./page/Competitive/1_Metagame/4_MetaCountersCompetitive/Meta Breakers Competitive";
import DraftingMetagameCompetitive
    from "./page/Competitive/1_Metagame/3_DraftingMetagameCompetitive/DraftingMetagameCompetitive";
import PlayersTierlists from "./page/Competitive/3_Players/9_PlayersRatingsCompetitive/Players Tierlists";
import BestBlindsCompetitive from "./page/Competitive/1_Metagame/4_MetaCountersCompetitive/BestBlinds";
import Hitmap_kills from "./page/Competitive/4_Broadcast/5_HitmapKills/Kills_hitmap";
import TeamMatchHistory from "./page/Competitive/2_Scouting/6_GenericOpponentScouting/TeamScouting";
import WardMaps from "./page/Competitive/2_Scouting/7_MacroScouting/WardMaps";
import JunglePathing from "./page/Competitive/2_Scouting/7_MacroScouting/JunglePathing";
import ScoutingSummary from "./page/Competitive/2_Scouting/6_GenericOpponentScouting/ScoutingSummary";
import TeamDrafts from "./page/Competitive/2_Scouting/8_DraftScouting/TeamDrafts";
import SoloQChampionsRatings from "./page/SoloQ/1_Metagame/11_ChampionsRatingsSoloQ/SoloQChampionsRatings";
import SoloQPlayerSummary from "./page/SoloQ/2_Scouting/14_GenericSoloQScouting/SoloQPlayerSummary";
import SoloQMonitoring from "./page/SoloQ/4_Monitoring/SoloQMonitoring";
import SoloQPlayerRanking from "./page/SoloQ/3_Players/15_PlayersRatingsSoloQ/SoloQPlayerRanking";
import OffseasonPlayerAnalysis from "./page/Offseason/17_OffseasonAnalysis/SpecificPlayer";
import StatsVs, {StatsH2H, TeamStatsInSetOfGames} from "./page/Competitive/4_Broadcast/StatsVs";
import ScrimCalendar from "./page/Scrims/1_Planning/Calendar";
import DraftingSim from "./page/Competitive/1_Metagame/DraftingSim";
import Shortlists from "./page/Offseason/17_OffseasonAnalysis/Shortlists";
import ScrimOpponents from "./page/Scrims/1_Planning/Opponents";
import ShortlistStats from "./page/Offseason/17_OffseasonAnalysis/ShortlistStats";
import Box from "@mui/material/Box";
import ScrimsChampionsRatings from "./page/Scrims/2_Metagame/ScrimsChampionsRatings";
import LeagueMatchHistory from "./page/Competitive/4_Broadcast/LeagueHistory";
import ReferentialContext, {targets, useReferentialContext} from "./Context";
import {Configuration, Login, PlayerApi} from "./farsight-api";
import {
    ChampionsDetailsChampionsQueue,
    ChampionsDetailsCompetitive,
    ChampionsDetailsScrims,
    ChampionsDetailsSoloQ
} from "./page/Shared/ChampionDetails";
import CompetitivePlayersDetails, {ScrimPlayerDetails, SoloQPlayersDetails} from "./page/Shared/PlayerDetails";
import GameReviewer from "./page/Scrims/4_Gameplay/ReviewGame";
import TeamChampionsScrims from "./page/Scrims/2_Metagame/TeamChampions";
import LeaguePlayersChampions from "./page/SoloQ/1_Metagame/LeaguePlayersChampions";
import FirstItemsEfficiency from "./page/SoloQ/1_Metagame/FirstItemsEfficiency";
import GoldsGraphs from "./page/Competitive/2_Scouting/GoldsGraphs";
import DashboardMain from "./page/Scrims/3_Monitoring/DashboardMain";
import DraftingSimDashboard from "./page/Competitive/1_Metagame/DraftingSimPerformances";
import CheckChampionSituationsCompetitive from "./page/Competitive/1_Metagame/CheckSituations";
import CheckDraftSituationsCompetitive from "./page/Competitive/1_Metagame/CheckDraftSituationsCompetitive";
import DraftPrep from "./page/Competitive/2_Scouting/DraftPrep";
import {SoloQChampionRatingsEvolution} from "./page/Shared/ChampionRatingsEvolution";
import LeaguePlayerElo from "./page/SoloQ/3_Players/LeaguePlayerElo";
import DraftingMetagameScrims from "./page/Scrims/2_Metagame/DraftingMetagameScrims";
import FindGameplayElements from "./page/Competitive/2_Scouting/FindGameplayElements";
import TeamSoloQSummaries from "./page/SoloQ/2_Scouting/TeamSoloQSummaries";
import {useThemeContext} from "./themeContext";
import PositionHeatmap from "./page/Competitive/2_Scouting/PositionHeatmap";
import CheckTeamfights from "./page/Competitive/2_Scouting/CheckTeamfights";
import ManageOrg from "./page/Management/ManageOrg";

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined as number | undefined,
        height: undefined as number | undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default function App(props: {theme: Theme}) {
    const size = useWindowSize();

    const stored_user = localStorage.getItem('farsightUser');

    const [user, setUser] = useState(stored_user ? JSON.parse(stored_user) : null as Login | null);
    const [userValidated, setUserValidated] = useState(true);

    if(user){
        const api_config = new Configuration({
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${user.token}`,
            },
            basePath: 'https://api.farsight-alteration.com'
        });
        const api = new PlayerApi(api_config);
        api.get({'id': 'Faker'}).then(p =>
            setUserValidated(true)
        ).catch(e => {
            setUserValidated(false);
            setUser(null);
        })

    }

    function log_user_in(user: any){
        setUser(user);
        setUserValidated(true)
    }

    function log_user_out(){
        setUser(null);
        setUserValidated(false);
        localStorage.removeItem('farsightUser');
    }

    document.title = "Farsight Alteration";
    if (!user?.token || !userValidated) {
        return <LoginPage setToken={log_user_in} />;
    }


    return (
        <ReferentialContext user={user} initTab={45}>
            <Box sx={{width: '100%', height:'100%'}}>
                <AppPages logout={log_user_out}/>
            </Box>
        </ReferentialContext>
    );
}

function AppPages(props: {logout: () => void}){
    const theme = useThemeContext();

    const tabs = [
        <CompetitiveChampionsRatings />,
        <ChampionsDetailsCompetitive />,
        <DraftingMetagameCompetitive />,
        <BotlaneMatchups />,
        <MetaBreakersCompetitive />,
        <CompetitivePlayersDetails />,
        <PlayersTierlists target={targets.competitive} />,
        <SoloQPlayersDetails />,
        <BestBlindsCompetitive />,
        <Hitmap_kills target={targets.competitive}/>,
        <TeamMatchHistory target={targets.competitive}/>,
        <WardMaps target={targets.competitive} />,
        <JunglePathing target={targets.competitive} />,
        <div />,
        <ScoutingSummary />,
        <TeamDrafts />,
        <DraftingSim />,
        <SoloQChampionsRatings />,
        <ChampionsDetailsSoloQ />,
        <SoloQPlayerSummary />,
        <SoloQMonitoring />,
        <SoloQPlayerRanking />,
        <OffseasonPlayerAnalysis />,
        <StatsVs />,
        <ScrimCalendar />,
        <ChampionsDetailsScrims />,
        <Shortlists />,
        <ScrimOpponents />,
        <ShortlistStats />,
        <ScrimsChampionsRatings />,
        <LeagueMatchHistory />,
        <ScrimPlayerDetails />,
        <TeamMatchHistory target={targets.scrims}/>,
        <GameReviewer target={targets.scrims}/>,
        <TeamChampionsScrims/>,
        <ChampionsDetailsChampionsQueue/>,
        <Hitmap_kills target={targets.scrims}/>,
        <LeaguePlayersChampions/>,
        <FirstItemsEfficiency/>,
        <StatsH2H/>,
        <TeamStatsInSetOfGames/>,
        <GoldsGraphs target={targets.competitive}/>,
        <JunglePathing target={targets.scrims} />,
        <DashboardMain/>,
        <WardMaps target={targets.scrims} />,
        <DraftingSimDashboard target={targets.competitive} />,
        <CheckChampionSituationsCompetitive/>,
        <CheckDraftSituationsCompetitive/>,
        <DraftPrep/>,
        <SoloQChampionRatingsEvolution/>,
        <LeaguePlayerElo/>,
        <DraftingMetagameScrims/>,
        <FindGameplayElements/>,
        <TeamSoloQSummaries/>,
        <PositionHeatmap target={targets.competitive}/>,
        <CheckTeamfights target={targets.competitive}/>,
        <ManageOrg/>,
    ];
    const ctx = useReferentialContext();
    return <Grid container sx={{ width: "100%", height: "100%" }}>
        <Box
            sx={{
                width: "100%",
                height: "100%", // Set the height to 100% of the parent
                position: "absolute",
                "&::before": {
                    content: '""',
                    display: "block",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundImage: theme.mode === 'dark' ? `url(https://cdna.artstation.com/p/assets/images/images/034/154/062/4k/chengwei-pan-1.jpg?1611561103)` : 'url(https://res.cloudinary.com/xenesis/image/upload/v1718638286/5464826_g9owhq.jpg)',
                    backgroundSize: theme.mode === 'dark' ? "100% 100%" : "100% 100%", // Zoom in on the image
                    filter: theme.mode === 'dark' ? "brightness(0.2)" : "brightness(1)",
                    zIndex: -1, // Place it behind the content
                    // backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                },
            }}
        >
            <CssBaseline />
            <Layout changePage={(event: any, newTab: number) => ctx.setCurrentTab(newTab)} current={ctx.current_tab} logout={props.logout}/>
            <Box
                sx={{
                    display: "flex",
                    height: "92%",
                    overflow: "auto",
                    zIndex: 10,
                    "&::-webkit-scrollbar": {
                        width: "0.2em",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#e1e1e1e",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                    },
                }}
            >
                {tabs.map((tab: any, index: number) => {
                    return (
                        <TabPanel value={ctx.current_tab} index={index}>
                            <Box
                                sx={{
                                    filter: "brightness(1)",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                {tab}
                            </Box>
                        </TabPanel>
                    );
                })}
            </Box>
        </Box>
    </Grid>
}