import {targets, useReferentialContext} from "../../Context";
import {Fade, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";


export default function ItemIcon(props: {item: string, sx? :any}){
    return <Fade in={true} style={{ transformOrigin: "0 0 0" }} {...{timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000)}} >
        <Tooltip title={props.item} followCursor={true}>
            {props.item ? <Box component={"img"}
                               src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/leagueAssets/${props.item}.png`}/> :
            <Box sx={{width:'40px', height:'40px', fill:'grey'}}/>}
        </Tooltip>
    </Fade>
}