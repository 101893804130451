/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    SoloQChampionRating,
    SoloQChampionRatingAggregate,
} from "../models/index";

export interface SoloQChampionRatingAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    champion?: string;
    champion_patch?: string;
    games?: number;
    having?: object;
    id?: string;
    index_on?: string;
    metrics?: Array<string>;
    patch?: string;
    role?: string;
    servers?: string;
}

export interface SoloQChampionRatingFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    champion?: string;
    champion_patch?: string;
    games?: number;
    id?: string;
    patch?: string;
    role?: string;
    servers?: string;
}

export interface SoloQChampionRatingGetRequest {
    id: string;
}

export interface SoloQChampionRatingListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    champion?: string;
    champion_patch?: string;
    games?: number;
    id?: string;
    ordering?: Array<string>;
    patch?: string;
    role?: string;
    servers?: string;
}

export interface SoloQChampionRatingVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    champion?: string;
    champion_patch?: string;
    games?: number;
    id?: string;
    patch?: string;
    role?: string;
    servers?: string;
}

/**
 *
 */
export class SoloQChampionRatingApi extends runtime.BaseAPI {
    /**
     */
    async soloQChampionRatingAggregateRaw(
        requestParameters: SoloQChampionRatingAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<SoloQChampionRatingAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling soloQChampionRatingAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling soloQChampionRatingAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["games"] != null) {
            queryParameters["games"] = requestParameters["games"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["servers"] != null) {
            queryParameters["servers"] = requestParameters["servers"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloQChampionRating/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: SoloQChampionRatingAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<SoloQChampionRatingAggregate>> {
        const response = await this.soloQChampionRatingAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloQChampionRatingFieldValuesRaw(
        requestParameters: SoloQChampionRatingFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling soloQChampionRatingFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling soloQChampionRatingFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["games"] != null) {
            queryParameters["games"] = requestParameters["games"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["servers"] != null) {
            queryParameters["servers"] = requestParameters["servers"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloQChampionRating/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: SoloQChampionRatingFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.soloQChampionRatingFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloQChampionRatingGetRaw(
        requestParameters: SoloQChampionRatingGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<SoloQChampionRating>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling soloQChampionRatingGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloQChampionRating/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: SoloQChampionRatingGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<SoloQChampionRating> {
        const response = await this.soloQChampionRatingGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloQChampionRatingListRaw(
        requestParameters: SoloQChampionRatingListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<SoloQChampionRating>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["games"] != null) {
            queryParameters["games"] = requestParameters["games"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["servers"] != null) {
            queryParameters["servers"] = requestParameters["servers"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloQChampionRating/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: SoloQChampionRatingListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<SoloQChampionRating>> {
        const response = await this.soloQChampionRatingListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloQChampionRatingVariableDistributionRaw(
        requestParameters: SoloQChampionRatingVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling soloQChampionRatingVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling soloQChampionRatingVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling soloQChampionRatingVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling soloQChampionRatingVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_patch"] != null) {
            queryParameters["champion_patch"] =
                requestParameters["champion_patch"];
        }

        if (requestParameters["games"] != null) {
            queryParameters["games"] = requestParameters["games"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["servers"] != null) {
            queryParameters["servers"] = requestParameters["servers"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloQChampionRating/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: SoloQChampionRatingVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.soloQChampionRatingVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
