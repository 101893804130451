import {Stack} from "@mui/material";
import React, {useState} from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import WindowContainer from "../../../../components/layout/WindowContainer";
import FilterSet from "../../../../components/selectors/Filterset";
import {targets, useReferentialContext} from "../../../../Context";
import TableDisplayGrid, {rowStyle} from "../../../../components/datagrids/shared/TableDisplayGrid";
import GamesHistory from "../../../../components/datagrids/shared/GamesHistory";
import {Player} from "../../../../farsight-api";

export default function SoloQPlayerSummary(props: {player?: Player}) {
    const context = useReferentialContext();
    const api = context.farsightApi.getSummariesApi(targets.soloq);

    const [from, setFrom] = useState(dayjs().subtract(14, "day"));
    const [until, setUntil] = useState(dayjs().add(1,"day"));
    const [player, setPlayer] = useState(props.player ? props.player : context.selectedPlayer);

    const [paramObject, setParamObject] = useState({} as any);

    const [metrics, setMetrics] = useState(context.metricsReferential.filter(m => ["performance", "relative_performance", "games", "winrate", 'gd15', 'csd15'].includes(m.code)));

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.soloq} player={player} setPlayer={setPlayer}
                        date__gte={from} setDateGte={setFrom} date__lt={until} setDateLt={setUntil}/>
            {player ? <Stack direction="row" sx={{height: "100%",justifyContent: "center",width: "100%"}} spacing={1}>
                <Stack sx={{ height: "100%", justifyContent: "center", width: "35%"}} spacing={1}>
                    <Box sx={{ height: "50%", justifyContent: "center", width: "100%"}}>
                        <TableDisplayGrid
                            display_all={false}
                            request={() => context.farsightApi.soloqAccount.list({
                                player:player.player
                            })}
                            rowStyle={rowStyle.none}
                            disp_columns={['accountName','elo','server','season_wins','season_losses','source','tracked']}
                            sortBy={"elo"}
                            paramObject={paramObject}
                        />
                    </Box>
                    <TableDisplayGrid
                        display_all={false}
                        request={() => api.aggregate({
                            ...paramObject,
                            groupby: 'champion',
                            metrics: ["performance", "relative_performance", "games", "winrate", 'gd15', 'csd15'].toString(),
                            ordering: "performance",
                            format: "json",
                        })}
                        rowStyle={rowStyle.none}
                        disp_columns={['champion']}
                        sortBy={"games"}
                        paramObject={paramObject}
                        metrics={metrics}
                    />
                </Stack>
                <Box sx={{height: "100%",justifyContent: "center",width: "65%", }}>
                    <GamesHistory paramObject={paramObject} target={targets.soloq} hide_columns={['competitive_player']}/>
                </Box>
            </Stack> : <></>}
        </WindowContainer>
    );
}
