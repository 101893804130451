/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";

export interface ModelsEvaluateDraftRetrieveRequest {
    champions: string;
    patch: string;
}

export interface ModelsEvaluateDraftV3RetrieveRequest {
    champions: string;
    patch: string;
}

/**
 *
 */
export class ModelsApi extends runtime.BaseAPI {
    /**
     */
    async modelsEvaluateDraftRetrieveRaw(
        requestParameters: ModelsEvaluateDraftRetrieveRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["champions"] == null) {
            throw new runtime.RequiredError(
                "champions",
                'Required parameter "champions" was null or undefined when calling modelsEvaluateDraftRetrieve().',
            );
        }

        if (requestParameters["patch"] == null) {
            throw new runtime.RequiredError(
                "patch",
                'Required parameter "patch" was null or undefined when calling modelsEvaluateDraftRetrieve().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["champions"] != null) {
            queryParameters["champions"] = requestParameters["champions"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Models/EvaluateDraft`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async evaluate_draft(
        requestParameters: ModelsEvaluateDraftRetrieveRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.modelsEvaluateDraftRetrieveRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async modelsEvaluateDraftV3RetrieveRaw(
        requestParameters: ModelsEvaluateDraftV3RetrieveRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["champions"] == null) {
            throw new runtime.RequiredError(
                "champions",
                'Required parameter "champions" was null or undefined when calling modelsEvaluateDraftV3Retrieve().',
            );
        }

        if (requestParameters["patch"] == null) {
            throw new runtime.RequiredError(
                "patch",
                'Required parameter "patch" was null or undefined when calling modelsEvaluateDraftV3Retrieve().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["champions"] != null) {
            queryParameters["champions"] = requestParameters["champions"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Models/EvaluateDraftV3`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async evaluate_draft_v3(
        requestParameters: ModelsEvaluateDraftV3RetrieveRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.modelsEvaluateDraftV3RetrieveRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
