/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    ScrimTeamGameSummary,
    ScrimTeamGameSummaryAggregate,
} from "../models/index";

export interface ScrimTeamGameSummaryAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    conceded_soul?: boolean;
    date?: string;
    destroyed_first_turret?: boolean;
    dragons_killed?: number;
    game?: string;
    got_first_blood?: boolean;
    got_soul?: boolean;
    grublings_killed?: number;
    having?: object;
    index_on?: string;
    killed_first_drake?: boolean;
    killed_first_grubs?: boolean;
    killed_first_nashor?: boolean;
    killed_rift_herald?: boolean;
    metrics?: Array<string>;
    nashors_killed?: number;
    patch?: string;
    side?: string;
    team1?: string;
    team2?: string;
    win?: boolean;
}

export interface ScrimTeamGameSummaryFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    conceded_soul?: boolean;
    date?: string;
    destroyed_first_turret?: boolean;
    dragons_killed?: number;
    game?: string;
    got_first_blood?: boolean;
    got_soul?: boolean;
    grublings_killed?: number;
    killed_first_drake?: boolean;
    killed_first_grubs?: boolean;
    killed_first_nashor?: boolean;
    killed_rift_herald?: boolean;
    nashors_killed?: number;
    patch?: string;
    side?: string;
    team1?: string;
    team2?: string;
    win?: boolean;
}

export interface ScrimTeamGameSummaryGetRequest {
    id: string;
}

export interface ScrimTeamGameSummaryListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    conceded_soul?: boolean;
    date?: string;
    destroyed_first_turret?: boolean;
    dragons_killed?: number;
    game?: string;
    got_first_blood?: boolean;
    got_soul?: boolean;
    grublings_killed?: number;
    killed_first_drake?: boolean;
    killed_first_grubs?: boolean;
    killed_first_nashor?: boolean;
    killed_rift_herald?: boolean;
    nashors_killed?: number;
    ordering?: Array<string>;
    patch?: string;
    side?: string;
    team1?: string;
    team2?: string;
    win?: boolean;
}

export interface ScrimTeamGameSummaryVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    conceded_soul?: boolean;
    date?: string;
    destroyed_first_turret?: boolean;
    dragons_killed?: number;
    game?: string;
    got_first_blood?: boolean;
    got_soul?: boolean;
    grublings_killed?: number;
    killed_first_drake?: boolean;
    killed_first_grubs?: boolean;
    killed_first_nashor?: boolean;
    killed_rift_herald?: boolean;
    nashors_killed?: number;
    patch?: string;
    side?: string;
    team1?: string;
    team2?: string;
    win?: boolean;
}

/**
 *
 */
export class ScrimTeamGameSummaryApi extends runtime.BaseAPI {
    /**
     */
    async scrimTeamGameSummaryAggregateRaw(
        requestParameters: ScrimTeamGameSummaryAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ScrimTeamGameSummaryAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling scrimTeamGameSummaryAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling scrimTeamGameSummaryAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["conceded_soul"] != null) {
            queryParameters["conceded_soul"] =
                requestParameters["conceded_soul"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["destroyed_first_turret"] != null) {
            queryParameters["destroyed_first_turret"] =
                requestParameters["destroyed_first_turret"];
        }

        if (requestParameters["dragons_killed"] != null) {
            queryParameters["dragons_killed"] =
                requestParameters["dragons_killed"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["got_first_blood"] != null) {
            queryParameters["got_first_blood"] =
                requestParameters["got_first_blood"];
        }

        if (requestParameters["got_soul"] != null) {
            queryParameters["got_soul"] = requestParameters["got_soul"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["grublings_killed"] != null) {
            queryParameters["grublings_killed"] =
                requestParameters["grublings_killed"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["killed_first_drake"] != null) {
            queryParameters["killed_first_drake"] =
                requestParameters["killed_first_drake"];
        }

        if (requestParameters["killed_first_grubs"] != null) {
            queryParameters["killed_first_grubs"] =
                requestParameters["killed_first_grubs"];
        }

        if (requestParameters["killed_first_nashor"] != null) {
            queryParameters["killed_first_nashor"] =
                requestParameters["killed_first_nashor"];
        }

        if (requestParameters["killed_rift_herald"] != null) {
            queryParameters["killed_rift_herald"] =
                requestParameters["killed_rift_herald"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["nashors_killed"] != null) {
            queryParameters["nashors_killed"] =
                requestParameters["nashors_killed"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimTeamGameSummary/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: ScrimTeamGameSummaryAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ScrimTeamGameSummaryAggregate>> {
        const response = await this.scrimTeamGameSummaryAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimTeamGameSummaryFieldValuesRaw(
        requestParameters: ScrimTeamGameSummaryFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling scrimTeamGameSummaryFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling scrimTeamGameSummaryFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["conceded_soul"] != null) {
            queryParameters["conceded_soul"] =
                requestParameters["conceded_soul"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["destroyed_first_turret"] != null) {
            queryParameters["destroyed_first_turret"] =
                requestParameters["destroyed_first_turret"];
        }

        if (requestParameters["dragons_killed"] != null) {
            queryParameters["dragons_killed"] =
                requestParameters["dragons_killed"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["got_first_blood"] != null) {
            queryParameters["got_first_blood"] =
                requestParameters["got_first_blood"];
        }

        if (requestParameters["got_soul"] != null) {
            queryParameters["got_soul"] = requestParameters["got_soul"];
        }

        if (requestParameters["grublings_killed"] != null) {
            queryParameters["grublings_killed"] =
                requestParameters["grublings_killed"];
        }

        if (requestParameters["killed_first_drake"] != null) {
            queryParameters["killed_first_drake"] =
                requestParameters["killed_first_drake"];
        }

        if (requestParameters["killed_first_grubs"] != null) {
            queryParameters["killed_first_grubs"] =
                requestParameters["killed_first_grubs"];
        }

        if (requestParameters["killed_first_nashor"] != null) {
            queryParameters["killed_first_nashor"] =
                requestParameters["killed_first_nashor"];
        }

        if (requestParameters["killed_rift_herald"] != null) {
            queryParameters["killed_rift_herald"] =
                requestParameters["killed_rift_herald"];
        }

        if (requestParameters["nashors_killed"] != null) {
            queryParameters["nashors_killed"] =
                requestParameters["nashors_killed"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimTeamGameSummary/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: ScrimTeamGameSummaryFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.scrimTeamGameSummaryFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimTeamGameSummaryGetRaw(
        requestParameters: ScrimTeamGameSummaryGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<ScrimTeamGameSummary>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling scrimTeamGameSummaryGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimTeamGameSummary/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: ScrimTeamGameSummaryGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<ScrimTeamGameSummary> {
        const response = await this.scrimTeamGameSummaryGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimTeamGameSummaryListRaw(
        requestParameters: ScrimTeamGameSummaryListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<ScrimTeamGameSummary>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["conceded_soul"] != null) {
            queryParameters["conceded_soul"] =
                requestParameters["conceded_soul"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["destroyed_first_turret"] != null) {
            queryParameters["destroyed_first_turret"] =
                requestParameters["destroyed_first_turret"];
        }

        if (requestParameters["dragons_killed"] != null) {
            queryParameters["dragons_killed"] =
                requestParameters["dragons_killed"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["got_first_blood"] != null) {
            queryParameters["got_first_blood"] =
                requestParameters["got_first_blood"];
        }

        if (requestParameters["got_soul"] != null) {
            queryParameters["got_soul"] = requestParameters["got_soul"];
        }

        if (requestParameters["grublings_killed"] != null) {
            queryParameters["grublings_killed"] =
                requestParameters["grublings_killed"];
        }

        if (requestParameters["killed_first_drake"] != null) {
            queryParameters["killed_first_drake"] =
                requestParameters["killed_first_drake"];
        }

        if (requestParameters["killed_first_grubs"] != null) {
            queryParameters["killed_first_grubs"] =
                requestParameters["killed_first_grubs"];
        }

        if (requestParameters["killed_first_nashor"] != null) {
            queryParameters["killed_first_nashor"] =
                requestParameters["killed_first_nashor"];
        }

        if (requestParameters["killed_rift_herald"] != null) {
            queryParameters["killed_rift_herald"] =
                requestParameters["killed_rift_herald"];
        }

        if (requestParameters["nashors_killed"] != null) {
            queryParameters["nashors_killed"] =
                requestParameters["nashors_killed"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimTeamGameSummary/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: ScrimTeamGameSummaryListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<ScrimTeamGameSummary>> {
        const response = await this.scrimTeamGameSummaryListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async scrimTeamGameSummaryVariableDistributionRaw(
        requestParameters: ScrimTeamGameSummaryVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling scrimTeamGameSummaryVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling scrimTeamGameSummaryVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling scrimTeamGameSummaryVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling scrimTeamGameSummaryVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["conceded_soul"] != null) {
            queryParameters["conceded_soul"] =
                requestParameters["conceded_soul"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["destroyed_first_turret"] != null) {
            queryParameters["destroyed_first_turret"] =
                requestParameters["destroyed_first_turret"];
        }

        if (requestParameters["dragons_killed"] != null) {
            queryParameters["dragons_killed"] =
                requestParameters["dragons_killed"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["got_first_blood"] != null) {
            queryParameters["got_first_blood"] =
                requestParameters["got_first_blood"];
        }

        if (requestParameters["got_soul"] != null) {
            queryParameters["got_soul"] = requestParameters["got_soul"];
        }

        if (requestParameters["grublings_killed"] != null) {
            queryParameters["grublings_killed"] =
                requestParameters["grublings_killed"];
        }

        if (requestParameters["killed_first_drake"] != null) {
            queryParameters["killed_first_drake"] =
                requestParameters["killed_first_drake"];
        }

        if (requestParameters["killed_first_grubs"] != null) {
            queryParameters["killed_first_grubs"] =
                requestParameters["killed_first_grubs"];
        }

        if (requestParameters["killed_first_nashor"] != null) {
            queryParameters["killed_first_nashor"] =
                requestParameters["killed_first_nashor"];
        }

        if (requestParameters["killed_rift_herald"] != null) {
            queryParameters["killed_rift_herald"] =
                requestParameters["killed_rift_herald"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["nashors_killed"] != null) {
            queryParameters["nashors_killed"] =
                requestParameters["nashors_killed"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/ScrimTeamGameSummary/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: ScrimTeamGameSummaryVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.scrimTeamGameSummaryVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
