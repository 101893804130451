/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    CompetitiveParticipantTeamfight,
    CompetitiveParticipantTeamfightAggregate,
} from "../models/index";

export interface CompetitiveParticipantTeamfightAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    champion?: string;
    date?: string;
    flash_up?: boolean;
    game_summary?: string;
    has_ult?: boolean;
    having?: object;
    include_in_performance?: boolean;
    index_on?: string;
    league?: string;
    metrics?: Array<string>;
    patch?: string;
    performance?: number;
    phase?: string;
    player?: string;
    relative_performance?: number;
    role?: string;
    split?: string;
    team1?: string;
    team2?: string;
    teamfight?: number;
    win?: boolean;
}

export interface CompetitiveParticipantTeamfightFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    champion?: string;
    date?: string;
    flash_up?: boolean;
    game_summary?: string;
    has_ult?: boolean;
    include_in_performance?: boolean;
    league?: string;
    patch?: string;
    performance?: number;
    phase?: string;
    player?: string;
    relative_performance?: number;
    role?: string;
    split?: string;
    team1?: string;
    team2?: string;
    teamfight?: number;
    win?: boolean;
}

export interface CompetitiveParticipantTeamfightGetRequest {
    id: string;
}

export interface CompetitiveParticipantTeamfightListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    champion?: string;
    date?: string;
    flash_up?: boolean;
    game_summary?: string;
    has_ult?: boolean;
    include_in_performance?: boolean;
    league?: string;
    ordering?: Array<string>;
    patch?: string;
    performance?: number;
    phase?: string;
    player?: string;
    relative_performance?: number;
    role?: string;
    split?: string;
    team1?: string;
    team2?: string;
    teamfight?: number;
    win?: boolean;
}

export interface CompetitiveParticipantTeamfightVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    champion?: string;
    date?: string;
    flash_up?: boolean;
    game_summary?: string;
    has_ult?: boolean;
    include_in_performance?: boolean;
    league?: string;
    patch?: string;
    performance?: number;
    phase?: string;
    player?: string;
    relative_performance?: number;
    role?: string;
    split?: string;
    team1?: string;
    team2?: string;
    teamfight?: number;
    win?: boolean;
}

/**
 *
 */
export class CompetitiveParticipantTeamfightApi extends runtime.BaseAPI {
    /**
     */
    async competitiveParticipantTeamfightAggregateRaw(
        requestParameters: CompetitiveParticipantTeamfightAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<CompetitiveParticipantTeamfightAggregate>>
    > {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling competitiveParticipantTeamfightAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling competitiveParticipantTeamfightAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["flash_up"] != null) {
            queryParameters["flash_up"] = requestParameters["flash_up"];
        }

        if (requestParameters["game_summary"] != null) {
            queryParameters["game_summary"] = requestParameters["game_summary"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["has_ult"] != null) {
            queryParameters["has_ult"] = requestParameters["has_ult"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["teamfight"] != null) {
            queryParameters["teamfight"] = requestParameters["teamfight"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveParticipantTeamfight/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: CompetitiveParticipantTeamfightAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveParticipantTeamfightAggregate>> {
        const response = await this.competitiveParticipantTeamfightAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveParticipantTeamfightFieldValuesRaw(
        requestParameters: CompetitiveParticipantTeamfightFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling competitiveParticipantTeamfightFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling competitiveParticipantTeamfightFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["flash_up"] != null) {
            queryParameters["flash_up"] = requestParameters["flash_up"];
        }

        if (requestParameters["game_summary"] != null) {
            queryParameters["game_summary"] = requestParameters["game_summary"];
        }

        if (requestParameters["has_ult"] != null) {
            queryParameters["has_ult"] = requestParameters["has_ult"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["teamfight"] != null) {
            queryParameters["teamfight"] = requestParameters["teamfight"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveParticipantTeamfight/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: CompetitiveParticipantTeamfightFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response =
            await this.competitiveParticipantTeamfightFieldValuesRaw(
                requestParameters,
                initOverrides,
            );
        return await response.value();
    }

    /**
     */
    async competitiveParticipantTeamfightGetRaw(
        requestParameters: CompetitiveParticipantTeamfightGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CompetitiveParticipantTeamfight>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling competitiveParticipantTeamfightGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveParticipantTeamfight/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: CompetitiveParticipantTeamfightGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CompetitiveParticipantTeamfight> {
        const response = await this.competitiveParticipantTeamfightGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveParticipantTeamfightListRaw(
        requestParameters: CompetitiveParticipantTeamfightListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CompetitiveParticipantTeamfight>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["flash_up"] != null) {
            queryParameters["flash_up"] = requestParameters["flash_up"];
        }

        if (requestParameters["game_summary"] != null) {
            queryParameters["game_summary"] = requestParameters["game_summary"];
        }

        if (requestParameters["has_ult"] != null) {
            queryParameters["has_ult"] = requestParameters["has_ult"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["teamfight"] != null) {
            queryParameters["teamfight"] = requestParameters["teamfight"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveParticipantTeamfight/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: CompetitiveParticipantTeamfightListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveParticipantTeamfight>> {
        const response = await this.competitiveParticipantTeamfightListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveParticipantTeamfightVariableDistributionRaw(
        requestParameters: CompetitiveParticipantTeamfightVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling competitiveParticipantTeamfightVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling competitiveParticipantTeamfightVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling competitiveParticipantTeamfightVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling competitiveParticipantTeamfightVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["flash_up"] != null) {
            queryParameters["flash_up"] = requestParameters["flash_up"];
        }

        if (requestParameters["game_summary"] != null) {
            queryParameters["game_summary"] = requestParameters["game_summary"];
        }

        if (requestParameters["has_ult"] != null) {
            queryParameters["has_ult"] = requestParameters["has_ult"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["league"] != null) {
            queryParameters["league"] = requestParameters["league"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["phase"] != null) {
            queryParameters["phase"] = requestParameters["phase"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["split"] != null) {
            queryParameters["split"] = requestParameters["split"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["teamfight"] != null) {
            queryParameters["teamfight"] = requestParameters["teamfight"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveParticipantTeamfight/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: CompetitiveParticipantTeamfightVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response =
            await this.competitiveParticipantTeamfightVariableDistributionRaw(
                requestParameters,
                initOverrides,
            );
        return await response.value();
    }
}
