/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    League,
    LeagueAggregate,
} from "../models/index";

export interface LeagueAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    bayes_name?: string;
    group?: string;
    having?: object;
    index_on?: string;
    is_international?: boolean;
    is_yearly_cup?: boolean;
    league_name?: string;
    metrics?: Array<string>;
}

export interface LeagueFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    bayes_name?: string;
    group?: string;
    is_international?: boolean;
    is_yearly_cup?: boolean;
    league_name?: string;
}

export interface LeagueGetRequest {
    id: string;
}

export interface LeagueListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    bayes_name?: string;
    group?: string;
    is_international?: boolean;
    is_yearly_cup?: boolean;
    league_name?: string;
    ordering?: Array<string>;
}

export interface LeagueVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    bayes_name?: string;
    group?: string;
    is_international?: boolean;
    is_yearly_cup?: boolean;
    league_name?: string;
}

/**
 *
 */
export class LeagueApi extends runtime.BaseAPI {
    /**
     */
    async leagueAggregateRaw(
        requestParameters: LeagueAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<LeagueAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling leagueAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling leagueAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["bayes_name"] != null) {
            queryParameters["bayes_name"] = requestParameters["bayes_name"];
        }

        if (requestParameters["group"] != null) {
            queryParameters["group"] = requestParameters["group"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["is_international"] != null) {
            queryParameters["is_international"] =
                requestParameters["is_international"];
        }

        if (requestParameters["is_yearly_cup"] != null) {
            queryParameters["is_yearly_cup"] =
                requestParameters["is_yearly_cup"];
        }

        if (requestParameters["league_name"] != null) {
            queryParameters["league_name"] = requestParameters["league_name"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/League/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: LeagueAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<LeagueAggregate>> {
        const response = await this.leagueAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async leagueFieldValuesRaw(
        requestParameters: LeagueFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling leagueFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling leagueFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bayes_name"] != null) {
            queryParameters["bayes_name"] = requestParameters["bayes_name"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["group"] != null) {
            queryParameters["group"] = requestParameters["group"];
        }

        if (requestParameters["is_international"] != null) {
            queryParameters["is_international"] =
                requestParameters["is_international"];
        }

        if (requestParameters["is_yearly_cup"] != null) {
            queryParameters["is_yearly_cup"] =
                requestParameters["is_yearly_cup"];
        }

        if (requestParameters["league_name"] != null) {
            queryParameters["league_name"] = requestParameters["league_name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/League/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: LeagueFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.leagueFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async leagueGetRaw(
        requestParameters: LeagueGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<League>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling leagueGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/League/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: LeagueGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<League> {
        const response = await this.leagueGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async leagueListRaw(
        requestParameters: LeagueListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<League>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["bayes_name"] != null) {
            queryParameters["bayes_name"] = requestParameters["bayes_name"];
        }

        if (requestParameters["group"] != null) {
            queryParameters["group"] = requestParameters["group"];
        }

        if (requestParameters["is_international"] != null) {
            queryParameters["is_international"] =
                requestParameters["is_international"];
        }

        if (requestParameters["is_yearly_cup"] != null) {
            queryParameters["is_yearly_cup"] =
                requestParameters["is_yearly_cup"];
        }

        if (requestParameters["league_name"] != null) {
            queryParameters["league_name"] = requestParameters["league_name"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/League/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: LeagueListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<League>> {
        const response = await this.leagueListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async leagueVariableDistributionRaw(
        requestParameters: LeagueVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling leagueVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling leagueVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling leagueVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling leagueVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bayes_name"] != null) {
            queryParameters["bayes_name"] = requestParameters["bayes_name"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["group"] != null) {
            queryParameters["group"] = requestParameters["group"];
        }

        if (requestParameters["is_international"] != null) {
            queryParameters["is_international"] =
                requestParameters["is_international"];
        }

        if (requestParameters["is_yearly_cup"] != null) {
            queryParameters["is_yearly_cup"] =
                requestParameters["is_yearly_cup"];
        }

        if (requestParameters["league_name"] != null) {
            queryParameters["league_name"] = requestParameters["league_name"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/League/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: LeagueVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.leagueVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
