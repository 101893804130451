/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type { Login } from "../models/index";

export interface LoginCreateRequest {
    username: string;
    password: string;
    token: string;
}

/**
 *
 */
export class LoginApi extends runtime.BaseAPI {
    /**
     */
    async loginCreateRaw(
        requestParameters: LoginCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Login>> {
        if (requestParameters["username"] == null) {
            throw new runtime.RequiredError(
                "username",
                'Required parameter "username" was null or undefined when calling loginCreate().',
            );
        }

        if (requestParameters["password"] == null) {
            throw new runtime.RequiredError(
                "password",
                'Required parameter "password" was null or undefined when calling loginCreate().',
            );
        }

        if (requestParameters["token"] == null) {
            throw new runtime.RequiredError(
                "token",
                'Required parameter "token" was null or undefined when calling loginCreate().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: "application/x-www-form-urlencoded" },
            { contentType: "multipart/form-data" },
            { contentType: "application/json" },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters["username"] != null) {
            formParams.append("username", requestParameters["username"] as any);
        }

        if (requestParameters["password"] != null) {
            formParams.append("password", requestParameters["password"] as any);
        }

        if (requestParameters["token"] != null) {
            formParams.append("token", requestParameters["token"] as any);
        }

        const response = await this.request(
            {
                path: `/Login`,
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: formParams,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: LoginCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Login> {
        const response = await this.loginCreateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
