/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    CompetitiveTeamfight,
    CompetitiveTeamfightAggregate,
} from "../models/index";

export interface CompetitiveTeamfightAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    aggregates?: Array<string>;
    game?: string;
    having?: object;
    index_on?: string;
    looser_team?: string;
    metrics?: Array<string>;
    objective_killer_team?: string;
    team1?: string;
    team2?: string;
    winner_team?: string;
}

export interface CompetitiveTeamfightFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    game?: string;
    looser_team?: string;
    objective_killer_team?: string;
    team1?: string;
    team2?: string;
    winner_team?: string;
}

export interface CompetitiveTeamfightGetRequest {
    id: string;
}

export interface CompetitiveTeamfightListRequest {
    additional_filters?: object;
    annotations?: Array<string>;
    game?: string;
    looser_team?: string;
    objective_killer_team?: string;
    ordering?: Array<string>;
    team1?: string;
    team2?: string;
    winner_team?: string;
}

export interface CompetitiveTeamfightVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    game?: string;
    looser_team?: string;
    objective_killer_team?: string;
    team1?: string;
    team2?: string;
    winner_team?: string;
}

/**
 *
 */
export class CompetitiveTeamfightApi extends runtime.BaseAPI {
    /**
     */
    async competitiveTeamfightAggregateRaw(
        requestParameters: CompetitiveTeamfightAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CompetitiveTeamfightAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling competitiveTeamfightAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling competitiveTeamfightAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["looser_team"] != null) {
            queryParameters["looser_team"] = requestParameters["looser_team"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["objective_killer_team"] != null) {
            queryParameters["objective_killer_team"] =
                requestParameters["objective_killer_team"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["winner_team"] != null) {
            queryParameters["winner_team"] = requestParameters["winner_team"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveTeamfight/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: CompetitiveTeamfightAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveTeamfightAggregate>> {
        const response = await this.competitiveTeamfightAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveTeamfightFieldValuesRaw(
        requestParameters: CompetitiveTeamfightFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling competitiveTeamfightFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling competitiveTeamfightFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["looser_team"] != null) {
            queryParameters["looser_team"] = requestParameters["looser_team"];
        }

        if (requestParameters["objective_killer_team"] != null) {
            queryParameters["objective_killer_team"] =
                requestParameters["objective_killer_team"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["winner_team"] != null) {
            queryParameters["winner_team"] = requestParameters["winner_team"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveTeamfight/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: CompetitiveTeamfightFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.competitiveTeamfightFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveTeamfightGetRaw(
        requestParameters: CompetitiveTeamfightGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CompetitiveTeamfight>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling competitiveTeamfightGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveTeamfight/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: CompetitiveTeamfightGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CompetitiveTeamfight> {
        const response = await this.competitiveTeamfightGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveTeamfightListRaw(
        requestParameters: CompetitiveTeamfightListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<CompetitiveTeamfight>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["looser_team"] != null) {
            queryParameters["looser_team"] = requestParameters["looser_team"];
        }

        if (requestParameters["objective_killer_team"] != null) {
            queryParameters["objective_killer_team"] =
                requestParameters["objective_killer_team"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["winner_team"] != null) {
            queryParameters["winner_team"] = requestParameters["winner_team"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveTeamfight/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: CompetitiveTeamfightListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<CompetitiveTeamfight>> {
        const response = await this.competitiveTeamfightListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async competitiveTeamfightVariableDistributionRaw(
        requestParameters: CompetitiveTeamfightVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling competitiveTeamfightVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling competitiveTeamfightVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling competitiveTeamfightVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling competitiveTeamfightVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["game"] != null) {
            queryParameters["game"] = requestParameters["game"];
        }

        if (requestParameters["looser_team"] != null) {
            queryParameters["looser_team"] = requestParameters["looser_team"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["objective_killer_team"] != null) {
            queryParameters["objective_killer_team"] =
                requestParameters["objective_killer_team"];
        }

        if (requestParameters["team1"] != null) {
            queryParameters["team1"] = requestParameters["team1"];
        }

        if (requestParameters["team2"] != null) {
            queryParameters["team2"] = requestParameters["team2"];
        }

        if (requestParameters["winner_team"] != null) {
            queryParameters["winner_team"] = requestParameters["winner_team"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/CompetitiveTeamfight/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: CompetitiveTeamfightVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.competitiveTeamfightVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
